.global_section_clientFeedback {
  .react-multi-carousel-item {
    > div {
      position: relative;
      opacity: 0;
      display: flex;
      height: 250px;
      align-items: center;
      justify-content: center;
      > img {
        width: 100%;
        max-height: 100%;
      }
      & > .global_section_clientFeedback_carousel_content {
        position: absolute;
        top: 46%;
        left: 50%;
        width: 82%;
        font-size: 15px;
        padding: 0px 32px;
        margin-bottom: 0px;
        text-align: center;
        transform: translate(-50%, -50%);
        &::before,
        &:after {
          position: absolute;
          top: -4px;
          left: -28px;
          width: 20px;
          height: 20px;
          margin-right: 8px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url($bucket_url + "/assets/img/bubbleQuotesOpen.svg");
        }
        &:after {
          position: absolute;
          top: initial;
          bottom: -4px;
          left: initial;
          margin: 0px 0px 0px 8px;
          background-image: url($bucket_url + "/assets/img/bubbleQuotesClose.svg");
        }
        > p {
          margin-bottom: 8px;
        }
      }
    }
  }
  .react-multi-carousel-item--active {
    > div {
      opacity: 1;
    }
  }
  .react-multiple-carousel__arrow {
    z-index: 4;
  }
}

@media only screen and (min-width: $device-min-ipad) {
  .global_section_clientFeedback {
    padding: 56px 0px 20px;
    h1 {
      font-size: 21px;
      line-height: 30px;
      > p {
        display: block;
      }
    }
    &_carousel {
      max-width: calc(100% - 100px);
    }
    .react-multi-carousel-item {
      > div {
        height: 300px;
        > p {
          font-size: 19px;
          left: 50%;
          width: 70%;
        }
      }
    }
    .react-multi-carousel-item--active {
      > div {
        width: calc(100% + 30px);
        margin-left: 16px;
        opacity: 1;
      }
      + .react-multi-carousel-item--active {
        > div {
          margin-left: -20px;
          width: auto;
          min-width: calc(100% + 20px);
        }
        + .react-multi-carousel-item--active {
          > div {
            margin-left: -20px;
            min-width: 100%;
          }
        }
      }
      .squareBubble {
        margin-top: 80px;
      }
    }
    .react-multiple-carousel__arrow {
      top: initial;
      bottom: 0;
      z-index: 4;
      &--left {
        left: 20px;
      }
      &--right {
        right: 20px;
      }
    }
  }
}

@media only screen and (min-width: $device-min-ipad) and (max-width: 1200px) {
  .global_section_clientFeedback {
    h1 {
      > p {
        display: inline;
      }
    }
    .react-multi-carousel-item {
      > div {
        height: 300px;
        > p {
          font-size: 17px;
          left: 50%;
          width: auto;
        }
      }
    }
    .react-multi-carousel-item--active {
      > div {
        width: calc(100% + 10px);
      }
      + .react-multi-carousel-item--active {
        > div {
          margin-left: -20px;
          width: auto;
          min-width: auto;
        }
      }
      .squareBubble {
        margin-top: 0;
      }
      &:nth-child(2n) {
        margin-top: 50px;
      }
    }
  }
}

@media only screen and (max-width: $device-max-mobile) and (orientation: landscape) {
  .global_section_clientFeedback {
    .react-multi-carousel-item {
      > div {
        height: 300px;
        > p {
          font-size: 17px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1500px) {
  // For 13 inch mac and smaller desktops, the carousal overflows
  .global_section_clientFeedback {
    .react-multi-carousel-item--active {
      > div {
        width: calc(100% + 20px);
      }
      + .react-multi-carousel-item--active {
        > div {
          margin-left: -10px;
          width: auto;
          min-width: calc(100% + 30px);
        }
        + .react-multi-carousel-item--active {
          > div {
            margin-left: -30px;
            min-width: 100%;
          }
        }
      }
    }
    .react-multi-carousel-item {
      > div {
        > p {
          font-size: 16px;
          width: 70%;
        }
      }
    }
  }
}
