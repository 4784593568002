.regYetToStart {
  position: relative;
  background-size: cover;
  background-position: 0px;
  background-repeat: no-repeat;
  min-height: 550px;
  background-image: url($bucket_url + "/assets/img/regYetToStartBg.png");
  &_content {
    @include positionAbsoluteTop;
    top: 50%;
    @include transformTranslateY(50%);
    & > h1 {
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      margin-bottom: 58px;
    }
  }
}
.regYetToStart--onIe {
  min-height: calc(100vh - 252px);
}
.joinMailListContent {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  & > small,
  & > span {
    flex: auto;
    font-size: 22px;
    @include inlineViewMiddle;
  }
  & > small {
    cursor: pointer;
    max-width: 120px;
    //margin-left: 44px;
  }
  & > span {
    max-width: 440px;
    line-height: 30px;
    font-family: $font-bold;
    & > small {
      font-size: 22px;
    }
    & > span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.joinMailListContent span {
  font-size: 22px;
  line-height: 24px;
  margin: 16px auto 20px;
  display: block;
}

.joinMailListContent{
  display: block;
}

@media (max-width: $device-small-desktop) {
  .regYetToStart {
    min-height: 550px;
  }
}

@media only screen and (max-width: $device-max-mobile) {
  .regYetToStart {
    &_content {
      position: relative;
      top: 0px;
      margin: 32px auto;
      @include transformTranslateY(0%);
      & > h1 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 24px;
        & > br {
          display: none;
        }
      }
    }
  }
  .joinMailListContent {
    display: block;
    & > small,
    & > span {
      display: block;
    }
    & > small {
      max-width: 80px;
      margin: 0px auto;
    }
    & > span {
      font-size: 16px;
      line-height: 24px;
      margin: 16px auto 0px;
      & > small {
        font-size: 16px;
      }
      & > br {
        display: none;
      }
    }
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) {
  .regYetToStart {
    &_content {
      & > h1 {
        & > br {
          display: none;
        }
      }
    }
  }
}
