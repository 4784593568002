.global {
  &_section {
    position: relative;
    padding: 32px 0px;
    &_title {
      position: relative;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      margin: 0px auto 32px;
      & > span {
        color: $titleHighlight;
      }
    }
    &_scrollingContent {
      background-color: $header_color;
      padding: 25px 0;
      ul {
        li {
          font-family: $font-semi;
          font-size: 30px;
          font-style: italic;
          color: $white;
          padding: 0 50px;
        }
      }
      .marquee-container {
        .overlay {
          display: none;
        }
      }
    }
    &_intro {
      padding: 0px;
      height: 252px;
      background-size: auto 100%;
      background-position: center;
      background-repeat: repeat-x;
      background-color: #e1f8c7;
      border-bottom: 2px solid $border-color;
      background-image: url($bucket_url + "/assets/img/introBG.png");
      & > img {
        position: relative;
        z-index: 6;
        bottom: -26px;
        display: block;
        max-width: 610px;
        margin: 0px auto;
      }
    }
    &_introVideo {
      padding: 0;
      &_wrapper {
        width: 100%;
        height: auto;
        line-height: 0;
        video {
          width: 100%;
        }
      }
    }
    &_welcome {
      width: 100%;
      height: 100%;
      background-image: url(../../../assets/img/welcomeImg_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-bottom: 2px solid $text-color;
      padding-bottom: 75px;
      &_img {
        padding-bottom: 75px;
        &_top {
          padding-bottom: 45px;
        }
        &_bottom {
          padding-top: 45px;
        }
      }
      &_toptext {
        .col{
          width: 33.33%;
          max-width: 33%;
          display: inline-block;
          padding: 10px 3% 0px 0;
          font-size: 16px;
          line-height: 24px;
          min-height: 200px;
          vertical-align: top;
        }
        h3{
          font-size: 36px;
          line-height: 44px;
        }
      }
    }
    &_loveFintech {
      .global_section_title {
        margin-top: 50px;
      }
    }
    &_clientFeedback {
      position: relative;
      padding: 56px 0px 20px;
      background-size: cover;
      background-repeat: repeat-x;
      background-position: bottom;
      border-bottom: 2px solid $border-color;
      background-image: url("../../../assets/img/carousel_bg.svg");
      h1 {
        font-size: 16px;
        line-height: 22px;
        font-family: $font-bold;
      }
      &_carousel {
        position: relative;
        padding: 20px 0 0;
        max-width: calc(100% - 10px);
        margin: 0 auto;
      }
    }
    &_about {
      text-align: center;
      padding-bottom: 0px;
      padding: 0;
      max-width: 1920px;
      margin: auto;
      &::after {
        content: "";
        @include positionAbsoluteBottomLeft;
        z-index: 4;
        width: 100%;
        height: 43px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        background-image: url($bucket_url + "/assets/img/about_section_purpleBar.png");
      }
      .container {
        padding-right: 0;
      }
      &_content {
        position: relative;
        font-size: 0px;
        text-align: left;
        padding-bottom: 10px;
        overflow: hidden;
        display: flex;
        &_left,
        &_right {
          position: relative;
          width: 54%;
          font-size: $font-md;
        }
        &_left {
          padding: 60px 36px 60px 54px;
          @include flexHCenter;
          flex-direction: column;
          .global_section_title {
            text-align: left;
            margin: 0px auto 24px;
          }
        }
        &_img {
          height: 100%;
          > img {
            max-height: 800%;
            width: 100%;
            object-fit: cover;
          }
        }
        &_right {
          width: 46%;
          z-index: 4;
          text-align: right;
          margin-bottom: -50px;
          & > img {
            @include positionAbsoluteBottomLeft;
            bottom: 34px;
          }
          > .global_section_what_content_img {
            max-width: 100%;
          }
        }
        &_video {
          position: relative;
          font-size: 11px;
          max-width: 350px;
          font-style: italic;
          color: var(--grey-1);
          margin: 0px 0px 42px 42px;
          > video {
            position: relative;
            width: auto;
            height: auto;
            display: block;
            cursor: pointer;
            max-width: 100%;
            max-height: 100%;
            margin: 0px auto;
            &::-webkit-media-controls-overlay-play-button {
              opacity: 0;
            }
          }
        }
        &_video {
          position: relative;
          font-size: 11px;
          max-width: 350px;
          font-style: italic;
          color: var(--grey-1);
          margin: 0px auto 45px;
          > video {
            position: relative;
            width: auto;
            height: auto;
            display: block;
            cursor: pointer;
            max-width: 100%;
            max-height: 100%;
            margin: 0px auto;
          }
        }
      }
    }
    &_eventsQone {
      background-image: url(../../../assets/img/welcomeImg_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-bottom: 2px solid $text-color;
    }
    &_who {
      border-top: 2px solid $text-color;
      border-bottom: 2px solid $text-color;
      padding: 30px;
    }
    &_why {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top right;
      border-bottom: 2px solid $text-color;
      background-image: url($bucket_url + "/assets/img/why_bg.png");
      padding-left: 30px;
      padding-right: 30px;
      & > b {
        display: block;
        padding: 0px 12px;
        font-size: $font-md;
        margin: 24px auto 0px;
      }
    }
    &_when {
      padding: 0px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom right;
      border-bottom: 2px solid $text-color;
      background-image: url($bucket_url + "/assets/img/when_bg.png");
      &_content {
        position: relative;
        padding: 70px 12px;
        & > .global_section_title {
          margin: 0px auto 32px;
        }
        & > p {
          font-size: 18px;
          line-height: 30px;
          text-align: center;
        }
      }
    }
    &_how {
      border-bottom: 2px solid $text-color;
      & > .container {
        position: relative;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: 24px 146px;
        background-image: url($bucket_url + "/assets/img/timeline-bg.png");
      }
      .step {
        position: relative;
        &.mobile {
          display: none;
        }
        &.getEmailIntros {
          .timeline-item {
            & > .content {
              & > p {
                margin-left: 24%;
              }
            }
          }
        }
        &.completeProfile {
          .timeline-item {
            & > .content {
              & > p {
                margin-left: 40%;
              }
            }
          }
        }
      }
      .row-1 {
        display: flex;
        align-items: center;
        justify-content: center;
        & > .step {
          padding: 0 0% 0 10%;
        }
      }
      .row-2 {
        margin-top: 280px;
        align-items: flex-start;
      }
      .timeline-item {
        & > .content {
          display: flex;
          font-size: 14px;
          text-align: left;
          line-height: 18px;
          align-items: center;
          justify-content: center;
          span {
            display: block;
            & > span {
              display: inline;
              color: $titleHighlight;
            }
          }
          &.withDate {
            & > label {
              width: 130px;
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              padding-right: 20px;
              font-family: $font-bold;
            }
            & > p {
              padding-left: 20px;
              text-align: left !important;
              border-left: 1px solid $border-color;
            }
          }
          &-image {
            display: none;
            border-radius: 20%;
            background-color: #fff;
          }
        }
      }
    }
    &_content {
      position: relative;
      & > p,
      & > ul {
        font-size: 16px;
        line-height: 24px;
      }
      & > p {
        & > b {
          display: inline;
        }
        & + p {
          margin-top: 20px;
        }
      }
      & > ul {
        margin: 24px auto;
        padding-left: 32px;
        list-style-type: decimal;
        & > li {
          & + li {
            margin-top: 8px;
          }
        }
        &.fm_include_list {
          padding-left: 0px;
          list-style-type: none;
          & > li {
            position: relative;
            font-size: 0px;
            & > span {
              position: relative;
              font-size: 16px;
              @include inlineViewTop;
              &:first-child {
                top: 4px;
                width: 16px;
                height: 16px;
                margin-right: 6px;
                border-radius: 2px;
                background-color: #51922c;
                &::before {
                  content: "";
                  @include positionAbsoluteTopLeft;
                  top: 2px;
                  left: 6px;
                  width: 5px;
                  height: 10px;
                  border: solid #fff;
                  transform: rotate(45deg);
                  -moz-transform: rotate(45deg);
                  border-width: 0px 2px 2px 0px;
                  -webkit-transform: rotate(45deg);
                }
              }
              &:last-child {
                width: calc(100% - 22px);
                width: -moz-calc(100% - 22px);
                width: -webkit-calc(100% - 22px);
              }
            }
          }
        }
      }
      &.hasLinkInContent {
        & > p {
          display: inline-block;
          & > a {
            margin: 0px 4px;
            text-decoration: underline;
            vertical-align: top;
          }
        }
      }
    }
    &_description {
      position: relative;
      & > p {
        font-size: 16px;
        line-height: 24px;
        & > a,
        & > span {
          display: unset;
          vertical-align: unset;
          text-decoration: underline;
        }
        & > span {
          & > span {
            cursor: pointer;
            color: $anchor-color;
            text-decoration: underline;
          }
        }
        & + p {
          margin-top: 24px;
        }
        & + i {
          display: block;
          font-size: 12px;
          margin: 6px auto 24px;
        }
      }
      & > .global_btn {
        margin-top: 24px;
      }
    }
    &_agendaSpeakers {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom right;
      background-image: url($bucket_url + "/assets/img/when_bg.png");
      padding: 32px 30px;
      .global_section_description {
        & > p {
          & > span {
            text-decoration: none;
            color: $titleHighlight;
            font-family: $font-bold;
          }
        }
      }
    }
    &_agendaSpeakers {
      &_includes {
        position: relative;
        margin: 24px auto;
        & > ul {
          font-size: 16px;
          margin: 0px auto;
          line-height: 20px;

          & > li {
            & + li {
              margin-top: 8px;
            }
            & > span {
              &:first-child {
                color: $titleHighlight;
                font-family: $font-bold;
              }
            }
            & > ul {
              padding-left: 32px;
              margin: 16px 0px 24px;
              list-style-type: disc;
              & > li {
                & + li {
                  margin-top: 8px;
                }
              }
            }
          }
        }
        & + p {
          margin-bottom: 16px;
        }
      }
      .global_btn {
        margin-top: 10px;
        display: inline-block;
        text-decoration: none;
      }
    }
    &_actionBtns {
      position: relative;
      margin-top: 24px;
      & > .global_btn {
        margin-right: 24px;
        &:last-child {
          margin-right: 0px;
        }
        &.seeAllSpeakers {
          padding: 12px 48px;
        }
      }
    }
  }
}
.global_section_content + .global_section_content {
  margin-top: 24px;
}
.past_event_details {
  position: relative;
  & > b {
    display: block;
    font-size: 24px;
    text-align: center;
    font-weight: normal;
    margin: 58px auto 44px;
    font-family: $font-title;
    background-color: $titleBg;
  }
  & > ul {
    margin: 0px -24px;
    & > li {
      width: 50%;
      padding: 0px 24px;
      text-align: center;
      margin-bottom: 48px;
      &:first-child,
      &:nth-child(2) {
        & > img {
          max-width: 320px;
        }
      }
      & > img {
        max-width: 80%;
      }
    }
  }
}
.joining {
  &_list {
    position: relative;
    margin-top: 44px;
    &_wrapper {
      position: relative;
      & > b {
        display: block;
        font-size: 24px;
        text-align: center;
        font-weight: normal;
        margin: 0px auto 44px;
        font-family: $font-title;
        background-color: $titleBg;
      }
      & > ul {
        display: flex;
        margin: 0px -12px;
        justify-items: stretch;
        align-items: flex-start;
        & > li {
          flex: 0 33.33%;
          padding: 0px 12px;
          max-width: 33.33%;
        }
      }
      &_section {
        position: relative;
        & > ul {
          font-size: 16px;
          line-height: 20px;
          padding-left: 18px;
          list-style-type: disc;
          & > li {
            & + li {
              margin-top: 6px;
            }
          }
        }
        & > b {
          position: relative;
          display: block;
          line-height: 24px;
          font-size: $font-md;
          margin-bottom: 20px;
        }
      }
    }
  }
}
.joining_list_wrapper + .joining_list_wrapper {
  margin-top: 44px;
}
.joining_list_wrapper_section + .joining_list_wrapper_section {
  margin-top: 36px;
}
.reasons {
  &_list {
    position: relative;
    margin: 40px auto 0px;
    & > ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -12px;
      justify-content: center;
      & > li {
        flex: 0 25%;
        max-width: 25%;
        padding: 0px 12px;
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 0px;
          & .reasons_list_box_img {
            height: auto;
            display: block;
          }
          & .reasons_list_box_img > span {
            display: block;
          }
          & .reasons_list_box_img > span {
            margin-bottom: 8px;
            & > img {
              max-height: 86px;
            }
          }
        }
      }
    }
    &_box {
      position: relative;
      text-align: center;
      &_img {
        position: relative;
        width: 100%;
        height: 86px;
        display: table;
        & > span {
          display: table-cell;
          vertical-align: middle;
          & > img {
            max-width: 90px;
            max-height: 82px;
            margin: 0px auto;
          }
        }
      }
      &_text {
        display: block;
        font-size: 22px;
        color: #335888;
        line-height: 24px;
        font-weight: normal;
        font-family: $font-title;
      }
    }
  }
}
.eventDetails {
  &_list {
    position: relative;
    & > ul {
      flex-wrap: wrap;
      margin: 0px -12px;
      flex-direction: row;
      @include displayFlex;
      & > li {
        flex: 50%;
        max-width: 50%;
        padding: 0px 12px;
        margin-bottom: 36px;
      }
    }
    &_item {
      position: relative;
      color: #12441d;
      text-align: center;
      & > img {
        display: block;
        max-height: 84px;
        margin: 0px auto 12px;
      }
      & > b {
        display: block;
        font-size: 24px;
      }
      & > p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.contentFormats {
  & > li {
    & + li {
      margin-top: 8px;
    }
  }
  &_item {
    position: relative;
    & > span {
      display: inline;
      vertical-align: middle;
      &:first-child {
        margin-right: 4px;
        font-family: $font-bold;
      }
    }
  }
}

@media only screen and (max-width: $device-small-desktop) {
  .global {
    &_section {
      &_welcome,
      &_loveFintech,
      &_eventsQone {
        &_img {
          max-width: 90%;
          margin: 0 auto;
        }
        &_toptext {
          max-width: 90%;
          margin: 0 auto;
        }
      }
      &_about {
        &_content {
          &_left {
            padding: 30px 45px;
          }
        }
      }
      &_scrollingContent {
        padding: 15px 0;
        ul > li {
          font-size: 24px;
        }
      }
    }
  }
  .past_event_details > ul {
    margin: 0px;
  }
}

@media only screen and (max-width: $device-max-mobile) {
  .global {
    &_section {
      &_title,
      &_tagLine {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 28px;
      }
      &_tagLine {
        &::before {
          top: 28px;
        }
      }
      &_content {
        & > p {
          & > br {
            display: none;
          }
        }
        & > b {
          margin-bottom: 16px;
        }
      }
      &_intro {
        height: 152px;
        & > img {
          bottom: -18px;
          max-width: 360px;
        }
      }
      &_clientFeedback {
        padding: 32px 0px;
      }
      &_clients {
        padding: 26px 0px 12px;
        h1 {
          font-size: 18px;
          line-height: 24px;
          & > br {
            display: none;
          }
        }
      }
      &_about {
        padding: 36px 0px 1px;
        &_content {
          display: block;
          padding-bottom: 0px;
          &_left,
          &_right {
            width: 100%;
            padding: 0px;
            display: block;
          }
          &_left {
            padding: 0 45px 24px;
          }
          &_right {
            text-align: center;
            margin: 0px auto;
            & img {
              position: relative;
              max-width: 100%;
              margin: 0px auto;
            }
          }
          &_video {
            margin: 32px auto;
          }
        }
      }
      &_when {
        &_content {
          padding: 24px;
          display: block;
          & > .global_section_title {
            margin-bottom: 28px;
          }
          & > p {
            font-size: 16px;
            line-height: 24px;
            & > br {
              display: none;
            }
          }
        }
      }
      &_actionBtns {
        & > .global_btn {
          display: block;
          margin-right: 0px;
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
      &_welcome {
        padding-bottom: 50px;
        &_img {
          padding-bottom: 50px;
          &_top {
            padding-bottom: 15px;
          }
          &_bottom {
            padding-top: 15px;
          }
        }
        &_toptext {
          .col{
            width: 90%;
            max-width: 90%;
            display: inline-block;
            //padding: 10px 3% 0px 0;
            font-size: 16px;
            line-height: 24px;
            // min-height: 200px;
           // vertical-align: top;
           padding-bottom:1em;
          }
          h3{
            font-size: 36px;
            line-height: 44px;
          }
        }
      }
    }
  }
  .joining {
    &_list {
      margin-top: 24px;
      &_wrapper {
        & > b {
          margin: 0px auto 24px;
        }
        & > ul {
          display: block;
          & > li {
            flex: none;
            display: block;
            max-width: none;
          }
        }
      }
    }
  }
  .joining_list_wrapper {
    & + .joining_list_wrapper {
      margin: 24px auto;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .joining_list_wrapper_section + .joining_list_wrapper_section {
    margin: 24px auto;
  }
  .reasons {
    &_list {
      & > ul {
        display: block;
        & > li {
          flex: none;
          display: block;
          max-width: none;
        }
      }
      &_box {
        &_img {
          height: auto;
        }
      }
    }
  }
  .past_event_details {
    & > b {
      margin: 32px auto;
    }
    & > ul {
      margin: 0px -12px;
      & > li {
        width: 100%;
        padding: 0px 12px;
        margin-bottom: 0px;
        & > img {
          max-width: 100% !important;
        }
        & + li {
          margin-top: 24px;
        }
      }
    }
  }
  .eventDetails {
    &_list {
      & > ul {
        flex-direction: column;
        & > li {
          flex: 100%;
          max-width: none;
        }
      }
    }
  }
}

@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) {
  .global {
    &_section {
      &_about {
        padding: 48px 0px 1px;
        &_content {
          display: block;
          padding-bottom: 0px;
          &_left,
          &_right {
            width: 100%;
            padding: 0px;
            display: block;
          }
          &_left {
            padding: 0 45px 30px;
          }
          &_video {
            max-width: 50%;
            margin: 55px auto;
          }
          &_right {
            text-align: center;
            margin: 0px auto -62px;
            & img {
              position: relative;
              bottom: 0px;
              max-width: 100%;
              margin: 0px auto;
            }
          }
          &_video {
            margin: 32px auto;
          }
        }
      }
      &_when {
        &_content {
          & > p {
            & > br {
              display: none;
            }
          }
        }
      }
      &_how {
        .row-2 {
          margin-top: 280px;
        }
      }
      &_welcome {
        padding-bottom: 50px;
        &_img {
          &_top {
            padding-bottom: 30px;
          }
          &_bottom {
            padding-top: 30px;
          }
        }
      }
    }
  }
  .past_event_details {
    & > ul {
      margin: 0px -12px;
      & > li {
        padding: 0px 12px;
      }
    }
  }
  .reasons {
    &_list {
      & > ul {
        & > li {
          width: 33.33%;
          flex: 0 33.33%;
          max-width: 33.33%;
        }
      }
    }
    &_box {
      &_text {
        & > br {
          display: none;
        }
      }
    }
  }
  .global {
    &_section {
      &_content {
        & > p {
          & > br {
            display: none;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) and (orientation: portrait) {
  .global {
    &_section {
      &_when {
        &_content {
          padding: 24px;
          display: block;
          &_left,
          &_right {
            width: 100%;
            display: block;
          }
          &_left {
            margin: 24px auto 0px;
          }
          &_right {
            margin-left: 0px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $device-max-ipad-potrait) {
  .global {
    &_section {
      &_how {
        & > .container {
          background-image: none;
        }
        .step {
          &.mobile {
            display: block;
          }
          &.getEmailIntros,
          &.completeProfile {
            .timeline-item {
              & > .content {
                & > p {
                  margin-left: 0px;
                }
              }
            }
          }
        }
        .row-1 {
          display: none;
        }
        .row-2 {
          margin-top: 40px;
        }
        .timeline-item {
          & > .content {
            & > p {
              margin: 16px 0px;
            }
            &-image {
              height: auto;
              max-width: 150px;
              padding: 0px 16px;
              @include inlineViewMiddle;
            }
          }
        }
      }
    }
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .global_section_what_content_right {
    display: block;
    .global_section_what_content_img {
      max-width: 100%;
      margin-bottom: 100px;
    }
    .global_section_what_content_video {
      margin: 0 auto 50px;
    }
  }
}
@media only screen and (max-width: $device-max-ipad) {
  .global_section {
    &_clientFeedback {
      & h1 {
        & > p {
          & > br {
            display: none;
          }
        }
      }
    }
    &_description {
      & > p {
        & > br {
          display: none;
        }
      }
    }
  }
  .reasons {
    &_list {
      &_box {
        &_text {
          & > br {
            display: none;
          }
        }
      }
    }
  }
}
