.applyCouponCode {
  margin: 60px 0 20px;
  form {
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    .textInput {
      width: 100%;
      margin-right: 20px;
      position: relative;
      label {
        text-align: left;
        width: 100%;
        display: block;
        margin-bottom: 8px;
        font-size: 16px;
        font-family: $font-bold;
      }
      input {
        width: 100% !important;
        color: #263271;
        appearance: none;
        padding: 10px 12px;
        -ms-appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border: 1px solid #263271;
        background-color: #fffce9;
        border-radius: 10px;
      }
    }
    button {
      position: relative;
      height: 40px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
      margin-top: 30px;
      margin: 0px auto;
      line-height: 20px;
      padding: 0px 24px;
      border-radius: 40px;
      font-family: $font-title;
      text-transform: uppercase;
      border: 1px solid #124419;
      background-color: #124419;
    }
    .error {
      color: $error;
      font-size: 12px;
      line-height: 14px;
      position: absolute;
      top: 76px;
    }
  }
}
