@font-face {
  font-family: "montserrat_regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/montserrat_regular.woff2") format("woff2"),
    url("../fonts/montserrat_regular.woff") format("woff");
}
@font-face {
  font-family: "montserrat_medium";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/montserrat_medium.woff2") format("woff2"),
    url("../fonts/montserrat_medium.woff") format("woff");
}
@font-face {
  font-family: "montserrat_bold";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/montserrat_bold.woff2") format("woff2"),
    url("../fonts/montserrat_bold.woff") format("woff");
}
@font-face {
  font-family: "bebas_neue";
  src: url("../fonts/bebas_neue.woff2") format("woff2"),
    url("../fonts/bebas_neue.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "brandon_grotesque";
  src: url("../fonts/brandongrotesque.woff2") format("woff2"),
    url("../fonts/brandongrotesque.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "brandon_grotesque_regular";
  src: url("../fonts/brandongrotesque_regular.woff2") format("woff2"),
    url("../fonts/brandongrotesque_regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gothambold";
  src: url("../fonts/gothambold-webfont.woff2") format("woff2"),
    url("../fonts/gothambold-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "gothammedium";
  src: url("../fonts/gothammedium-webfont.woff2") format("woff2"),
    url("../fonts/gothammedium-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}
