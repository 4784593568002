@import "./fonts";

// ############################################
// This variable will change per environment
// ############################################
$bucket_url: "https://a.fintechmeetup.com";


//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// These variables are the same per environment
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
$font-xs: 12px;
$font-std: 14px;
$font-md: 16px;
$font-lg: 20px;
$font-xl: 24px;

$font-regular: "montserrat_regular", sans-serif;
$font-semi: "montserrat_medium", sans-serif;
$font-bold: "montserrat_bold", sans-serif;
$font-title: "brandon_grotesque", sans-serif;
$font-brandon-regular: "brandon_grotesque_regular", "sans-serif";
$font-bebas-neue: "bebas_neue", sans-serif;
$font-gotham-medium: "gothammedium, sans-serif";
$font-gotham-bold: "gothambold, sans-serif";

$device-min-mobile: 320px;
$device-max-mobile: 767px;
$device-min-ipad: 768px;
$device-max-ipad: 1024px;
$device-desktop: 1025px;
$device-max-ipad-potrait: 990px;
$device-small-desktop: 1380px;
/////////////////////////

$lineheight-font-xs: 18px;
$lineheight-font-sm: 20px;
$lineheight-font-std: 22px;

/////////////////////////
