.coLocatedEvents {
  background-image: url(../../../assets/img/welcomeImg_bg.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  padding-bottom: 53px;
  .global_section {
    &_schedule_content {
      align-items: flex-start;
      flex-wrap: wrap;
      @include flex;
      &_fullWidth {
        flex: 0 0 100%;
        max-width: 100%;
        order: 3;
        margin: 0;
      }
      &_left {
        flex: 0 0 55%;
        max-width: 55%;
        order: 1;
      }
      &_right {
        flex: 0 0 45%;
        max-width: 45%;
        order: 2;
        margin-left: 0;
        padding-left: 36px;
        > img {
          max-width: 100%;
        }
      }
      .link_wrapper {
        display: inline-block;
        a {
          vertical-align: bottom;
          text-decoration: underline;
        }
      }
    }
    &_title {
      margin: 0;
      &_wrapper {
        @include displayFlex;
        img {
          margin-left: 35px;
        }
      }
    }
  }
}

@media only screen and (max-width: $device-max-mobile) {
  .coLocatedEvents {
    padding-bottom: 0;
    .global_section {
      &_schedule_content {
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 0;
        &_fullWidth {
          order: 2;
          margin-bottom: 62px;
        }
        &_left {
          flex: 0 0 100%;
          max-width: 100%;
          order: 1;
        }
        &_right {
          flex: 0 0 calc(100% + 30px);
          max-width: calc(100% + 30px);
          width: calc(100% + 30px);
          order: 3;
          padding-left: 0;
          margin: 0 -15px;
        }
      }
    }
  }
}
