*,
:after,
:before {
  @include boxSizing;
}
*,
:after,
:before,
input[type="checkbox"],
input[type="radio"] {
  @include boxSizing;
}
input[type="search"] {
  @include boxSizing;
  -webkit-appearance: none;
}
input[type="checkbox"],
input[type="radio"] {
  padding: 0px;
  @include boxSizing;
}
select {
  -webkit-appearance: none;
  &::-ms-expand {
    display: none;
  }
}
input[disabled],
select[disabled],
textarea[disabled] {
  cursor: not-allowed !important;
  background-color: $disabled !important;
}
input,
select,
textarea {
  font-size: $font-std;
  font-family: $font-semi;
}
*,
*:focus {
  outline: none;
}
img {
  max-width: 100%;
  vertical-align: bottom;
}
a {
  @include anchor_text;
}
a:focus,
a:hover {
  text-decoration: none;
}
p {
  margin: 0px auto;
}
body {
  margin: 0px;
  color: $text-color;
  font-size: $font-std;
  font-family: $font-semi;
  &.isMobilePrivacy {
    padding-top: 0px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  margin: 0px auto;
  font-weight: normal;
  font-family: $font-title;
}
b,
strong {
  display: block;
  margin: 0px auto;
  font-family: $font-bold;
}
