$gold: #fbc02d;
$silver: #6d6e71;
$bronze: #cd7e32;
$media: #151349;
.sponsors {
  &LogoWrapper {
    position: relative;
    margin-bottom: 68px;
  }
  &_topSection {
    position: relative;
    z-index: 8;
    padding: 40px 0px;
    background-color: #fff;
    &_content {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      &_left,
      &_right {
        flex: calc(100% - 276px);
        max-width: calc(100% - 276px);
        flex: -moz-calc(100% - 276px);
        flex: -webkit-calc(100% - 276px);
        max-width: -moz-calc(100% - 276px);
        max-width: -webkit-calc(100% - 276px);
      }
      &_right {
        flex: 276px 0;
        max-width: 276px;
        & > a {
          @include displayFlexCenter;
          & > button {
            width: 100%;
            display: block;
            color: $text-color;
            background-color: #fffce9;
            border: 1px solid $formElementBorder;
            &:hover {
              color: #fff;
              background-color: $primaryBtnBg;
            }
          }
        }
      }
      &_left {
        padding-right: 48px;
        & > p {
          font-size: 16px;
          line-height: 24px;
          font-family: $font-regular;
        }
      }
    }
  }
  &_wrapper {
    position: relative;
    border-top: 2px solid #263271;
    &_title {
      position: relative;
      text-align: center;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #263271;
      @include positionStickyTopLeft;
      top: 144px;
      background-color: $white;
      width: 100%;
      z-index: 10;
      &.isGoldSponsor {
        margin: 32px auto;
        padding: 20px 0;
      }
      &.isSilverSponsor {
        margin: 0 auto 32px;
        padding: 20px 0;
      }
      &.isBronzeSponsor {
        margin: 4px auto 32px;
        padding: 20px 0;
      }
      &.isMediaSponsor {
        margin: 10px auto 32px;
        padding: 20px 0;
      }
      & > span {
        position: relative;
        font-size: 48px;
        padding: 0px 44px;
        line-height: 38px;
        @include inlineViewMiddle;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0px;
          width: 32px;
          height: 7px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: left center;
          transform: scaleX(-1) translateY(-50%);
          -moz-transform: scaleX(-1) translateY(-50%);
          -webkit-transform: scaleX(-1) translateY(-50%);
        }
        &::after {
          left: auto;
          right: 0px;
          transform: scaleX(1) translateY(-50%);
          -moz-transform: scaleX(1) translateY(-50%);
          -webkit-transform: scaleX(1) translateY(-50%);
        }
      }
      &.isGoldSponsor {
        & > span {
          color: $gold;
          &::before,
          &::after {
            background-image: url($bucket_url + "/assets/img/gold_sponsor.png");
          }
        }
      }
      &.isSilverSponsor {
        & > span {
          color: $silver;
          &::before,
          &::after {
            background-image: url($bucket_url + "/assets/img/silver_sponsor.png");
          }
        }
      }
      &.isBronzeSponsor {
        & > span {
          color: $bronze;
          &::before,
          &::after {
            background-image: url($bucket_url + "/assets/img/bronze_sponsor.png");
          }
        }
      }
      &.isMediaSponsor {
        & > span {
          color: $media;
          &::before,
          &::after {
            background-image: url($bucket_url + "/assets/img/media_sponsor_arrow.png");
          }
        }
      }
    }
  }
  &_list {
    position: relative;
    & > ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      &.isGoldSponsor {
        margin: 0px -16px;
        & > li {
          flex: 50% 0;
          max-width: 50%;
          padding: 0px 16px;
          margin-bottom: 32px;
        }
      }
      &.isSilverSponsor {
        margin: 0px -14px;
        & > li {
          width: 33.33%;
          max-width: 33.33%;
          padding: 0px 14px;
          margin-bottom: 28px;
        }
      }
      &.isBronzeSponsor {
        margin: 0px -8px;
        & > li {
          width: 25%;
          max-width: 25%;
          padding: 0px 8px;
          margin-bottom: 16px;
        }
      }
      &.isMediaSponsor {
        margin: 0px -8px;
        & > li {
          width: 25%;
          max-width: 25%;
          padding: 0px 8px;
          margin-bottom: 16px;
        }
      }
    }
  }
  &_card {
    position: relative;
    border: 1px solid #d3d3d3;
    & > a {
      position: relative;
      height: 100%;
      display: block;
      min-height: 188px;
      padding: 12px 24px;
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 4;
        height: 100%;
        @include transition(0.3s);
        background-color: transparent;
      }
      &::after {
        content: attr(title);
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        z-index: 6;
        opacity: 0;
        font-size: 28px;
        line-height: 32px;
        padding: 0px 12px;
        color: $text-color;
        text-align: center;
        font-family: $font-title;
        @include transition(0.3s);
        @include transformTranslateY(-50%);
      }
      &:hover {
        &::after {
          opacity: 1;
        }
      }
      .isGoldSponsor & {
        &:hover {
          img,
          svg {
            opacity: 0;
          }
          &::before {
            background: linear-gradient(
              0deg,
              rgba(253, 227, 114, 0.5) 0%,
              rgba(203, 157, 64, 0.5) 100%
            );
          }
        }
      }
      .isSilverSponsor & {
        &:hover {
          img,
          svg {
            opacity: 0;
          }
          &::before {
            background: linear-gradient(
              0deg,
              rgba(153, 153, 153, 0.5) 0%,
              rgba(95, 96, 98, 0.5) 100%
            );
          }
        }
      }
      .isBronzeSponsor & {
        &:hover {
          img,
          svg {
            opacity: 0;
          }
          &::before {
            background: linear-gradient(
              0deg,
              rgba(250, 176, 113, 0.5) 0%,
              rgba(205, 102, 50, 0.5) 100%
            );
          }
        }
      }
      .isMediaSponsor & {
        &:hover {
          img,
          svg {
            opacity: 0;
          }
          &::before {
            background: linear-gradient(
              to bottom,
              rgba(11, 16, 68, 1) 0%,
              rgba(38, 53, 132, 1) 100%
            );
          }
          &::after {
            color: $white;
          }
        }
      }
      & > img,
      & > svg {
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        opacity: 1;
        display: block;
        margin: 0px auto;
        max-width: 188px;
        max-height: 160px;
        @include transition(0.3s);
        @include transformTranslateY(-50%);
      }
      & > svg {
        width: 64px;
        height: 64px;
        color: #686868;
      }
    }
    &.hasBrokenImg {
      background-color: #e2e7e9;
    }
  }
  &_viewFloorPlan {
    margin-top: 16px;
    background-color: $primaryBtnBg;
  }
}
.flipSponsorContent {
  flex-direction: row-reverse;
  .sponsors_topSection_content_left {
    padding-left: 48px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: $device-max-mobile) {
  .sponsors {
    &LogoWrapper {
      padding-top: 0px;
      margin-bottom: 24px;
    }
    &_topSection {
      position: relative;
      top: 0px;
      padding: 24px 0px;
      border-bottom: none;
      padding: 12px 0px 0px;
      .pageTitle {
        font-size: 32px;
        line-height: 36px;
        margin: 0px auto 16px;
      }
      &_content {
        display: block;
        &_left,
        &_right {
          flex: none;
          padding: 0px;
          max-width: none;
          text-align: center;
        }
        &_right {
          left: 0px;
          right: 0px;
          margin: 24px auto 12px;
          &.isSticky {
            position: fixed;
            top: 120px;
            z-index: 4;
            padding: 12px;
            margin: 0px auto;
            background-color: #fff;
            display: block !important;
            border-bottom: 2px solid #000;
          }
        }
      }
    }
    &_wrapper {
      &_title {
        top: 258px;
        z-index: 4;
        &.isGoldSponsor,
        &.isSilverSponsor,
        &.isBronzeSponsor,
        &.isMediaSponsor {
          margin: 0 auto 10px;
          padding: 12px 0;
        }
        & > span {
          font-size: 32px;
        }
      }
    }
    &_list {
      & > ul {
        &.isGoldSponsor,
        &.isSilverSponsor,
        &.isBronzeSponsor,
        &.isMediaSponsor {
          margin: 0px -6px;
          & > li {
            width: 50%;
            max-width: 50%;
            padding: 0px 6px;
            margin-bottom: 12px !important;
          }
        }
      }
    }
    &_card {
      & > a {
        height: 140px;
        min-height: 140px;
        &::after,
        &::before {
          content: none;
        }
        &:hover {
          & > img,
          svg {
            opacity: 1 !important;
          }
        }
        & > img {
          max-width: 120px;
          max-height: 120px;
        }
      }
    }
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) {
  .sponsors {
    &_wrapper {
      &_title {
        top: 158px;
      }
    }
    &_list {
      & > ul {
        &.isGoldSponsor,
        &.isSilverSponsor,
        &.isBronzeSponsor {
          margin: 0px -12px;
          & > li {
            padding: 0px 12px;
            margin-bottom: 24px !important;
          }
        }
      }
    }
    &_card {
      & > a {
        & > img {
          max-width: 120px;
        }
      }
    }
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) and (orientation: portrait) {
  .sponsors {
    &_wrapper {
      &_title {
        &.isGoldSponsor {
          margin: 24px auto;
        }
        &.isSilverSponsor,
        &.isBronzeSponsor {
          margin: 0px auto 24px;
        }
        & > span {
          font-size: 36px;
        }
      }
    }
    &_topSection {
      &_content {
        &_left {
          padding-right: 24px;
          flex: calc(100% - 230px) 1;
          max-width: calc(100% - 230px);
          flex: -moz-calc(100% - 230px) 1;
          max-width: -moz-calc(100% - 230px);
          flex: -webkit-calc(100% - 230px) 1;
          max-width: -webkit-calc(100% - 230px);
        }
        &_right {
          flex: 230px 0;
          max-width: 230px;
        }
      }
    }
    &_card {
      & > a {
        & > img {
          max-width: 120px;
        }
      }
    }
  }
}
@media only screen and (min-width: 790px) and (max-width: 990px) {
  .sponsors {
    &_wrapper {
      &_title {
        top: 205px;
        &.isGoldSponsor {
          margin: 9px auto;
          padding: 15px auto;
        }
        &.isSilverSponsor,
        &.isBronzeSponsor,
        &.isMediaSponsor {
          margin: 0px auto 15px;
          padding: 15px 0;
        }
        & > span {
          font-size: 36px;
        }
      }
    }
    &_topSection {
      &_content {
        &_left {
          padding-right: 24px;
          flex: calc(100% - 230px) 1;
          max-width: calc(100% - 230px);
          flex: -moz-calc(100% - 230px) 1;
          max-width: -moz-calc(100% - 230px);
          flex: -webkit-calc(100% - 230px) 1;
          max-width: -webkit-calc(100% - 230px);
        }
        &_right {
          flex: 230px 0;
          max-width: 230px;
        }
      }
    }
  }
}
