.fintech_wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100vw;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 92px);
  min-height: -moz-calc(100vh - 92px);
  min-height: -webkit-calc(100vh - 92px);
  > main,
  section {
    width: 100%;
    flex-grow: 1;
  }
  footer,
  header {
    width: 100%;
  }
}
body {
  font-synthesis: none;
}
.pageTitle {
  position: relative;
  font-size: 36px;
  line-height: 44px;
  margin: 0px auto 38px;
}
.global_btn {
  position: relative;
  color: $white;
  cursor: pointer;
  line-height: 22px;
  padding: 12px 24px;
  border-radius: 8px;
  text-align: center;
  font-size: $font-std;
  font-family: $font-bold;
  @include transition(0.5s);
  border: 1px solid transparent;
  background-color: $primaryBtnBg;
}

@media only screen and (max-width: $device-max-ipad-potrait) {
  .fintech_wrapper {
    min-height: calc(100vh - 90px);
    min-height: -moz-calc(100vh - 90px);
    min-height: -webkit-calc(100vh - 90px);
  }
}
