.main {
  &_privacy {
    .isMobilePrivacy & {
      margin-top: 32px;
    }
  }
}
.hasListStyle {
  list-style-type: square !important;
}
.static {
  &_page {
    &_contentHolder {
      position: relative;
      font-size: $font-md;
      padding-bottom: 32px;
      & > p {
        line-height: 24px;
        & + p {
          margin-top: 24px;
        }
        & > b {
          display: inline;
          vertical-align: baseline;
        }
      }
    }
    &_privacyCont {
      position: relative;
      margin: 24px auto;
      & > ul {
        padding-left: 16px;
        list-style-type: decimal;
        & > li {
          & > strong {
            display: block;
            margin-bottom: 12px;
            font-family: $font-bold;
          }
          & > p {
            position: relative;
            line-height: 24px;
            & > a,
            & > b {
              display: inline;
              vertical-align: baseline;
              text-decoration: underline;
            }
            & > b {
              text-decoration: none;
            }
            & + ul {
              margin: 16px 0px;
              padding-left: 32px;
              list-style-type: decimal;
            }
          }
          & > ul {
            padding-left: 16px;
            list-style-type: disc;
            & > li {
              line-height: 24px;
              & > b {
                display: inline;
                vertical-align: baseline;
              }
              & + li {
                margin-top: 12px;
              }
            }
          }
          & + li {
            margin-top: 24px;
          }
        }
      }
    }
    &_participationCont {
      position: relative;
      margin: 24px auto;
    }
    &_conditionsList {
      position: relative;
      margin-top: 24px;
      & ul {
        line-height: 24px;
        padding-left: 32px;
        list-style-type: decimal;
        & li {
          & + li {
            margin-top: 16px;
          }
          & > p {
            & > u {
              font-style: italic;
            }
            & > b,
            & > a {
              display: inline;
              vertical-align: baseline;
            }
            & > a {
              text-decoration: underline;
            }
            & > p {
              margin-top: 16px;
            }
          }
          & > .isSubList {
            position: relative;
            margin-top: 16px;
            line-height: 22px;
            padding-left: 36px;
            list-style-type: lower-alpha;
            &:empty {
              display: none;
            }
            &.hasListStyle {
              list-style-type: square;
            }
            & > li {
              & + li {
                margin-top: 16px;
              }
              & > p {
                & > b {
                  display: inline;
                  vertical-align: baseline;
                }
                & > .eventDates {
                  position: relative;
                  margin-top: 16px;
                  & > ul {
                    list-style-type: lower-roman;
                    & > li {
                      padding-left: 16px;
                      & > b {
                        display: block;
                        margin-bottom: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &_pageTitle {
    position: relative;
    font-size: 32px;
    line-height: 40px;
    color: $text-color;
    font-weight: normal;
    margin: 48px auto 24px;
    .isMobilePrivacy & {
      display: none;
    }
  }
  &_contentList {
    position: relative;
    font-size: 16px;
    & > b {
      display: block;
      margin: 0px auto 6px;
    }
    & > ul {
      line-height: 24px;
      padding-left: 32px;
      list-style-type: disc;
      & > li {
        color: $anchor-color;
        > * {
          vertical-align: text-top;
        }
        & > a {
          cursor: pointer;
        }
      }
    }
    &_sections {
      position: relative;
      margin: 24px auto 48px;
    }
    &_section {
      position: relative;
      font-size: $font-md;
      &_title {
        position: relative;
        font-size: 0px;
        display: block;
        margin: 0px auto 16px;
        & > svg,
        & > span {
          position: relative;
          font-size: 18px;
          @include inlineViewMiddle;
        }
        & > svg {
          left: -3px;
          width: 16px;
          height: 16px;
          fill: $anchor-color;
        }
        & > span {
          max-width: calc(100% - 22px);
          max-width: -moz-calc(100% - 22px);
          max-width: -webkit-calc(100% - 22px);
        }
      }
      & > p {
        line-height: 24px;
        & + p,
        & + .static_contentList_subSection {
          margin-top: 16px;
        }
        & > a,
        & > strong {
          display: inline;
          vertical-align: baseline;
          text-decoration: underline;
        }
      }
      & > ul {
        margin: 16px auto;
        padding-left: 32px;
        list-style-type: disc;
        &.hasListStyleType {
          list-style-type: square;
        }
        .static_page_participationCont & {
          padding-left: 36px;
        }
        & > li {
          line-height: 24px;
          & + li {
            margin-top: 8px;
          }
          & > a {
            display: inline;
            vertical-align: baseline;
            text-decoration: underline;
          }
          &:empty {
            display: none;
          }
        }
      }
    }
    &_subSection {
      position: relative;
      font-size: $font-md;
      & > strong {
        position: relative;
        display: block;
        margin: 0px auto 8px;
      }
      & > p {
        line-height: 24px;
        & + p {
          margin-top: 16px;
        }
        & > a {
          display: inline;
          vertical-align: baseline;
          text-decoration: underline;
        }
      }
      & > ul {
        margin: 16px auto;
        padding-left: 32px;
        list-style-type: disc;
        &.hasListStyleType {
          list-style-type: square;
        }
        .static_page_participationCont & {
          padding-left: 36px;
        }
        & > li {
          line-height: 24px;
          & + li {
            margin-top: 16px;
          }
          & > a {
            display: inline;
            vertical-align: baseline;
            text-decoration: underline;
          }
          &:empty {
            display: none;
          }
          & > p {
            position: relative;
            margin: 16px auto;
            & > strong {
              display: inline;
              vertical-align: baseline;
            }
            & + i {
              display: block;
              font-size: 12px;
            }
          }
          & > ul {
            margin-top: 8px;
            & > li {
              & + li {
                margin-top: 8px;
              }
              & > ul {
                margin-top: 8px;
              }
            }
          }
          & > .globalResponsiveTable {
            max-width: 74%;
            margin: 16px 0px 16px 36px;
          }
        }
      }
    }
  }
}
.static_contentList_section + .static_contentList_section {
  margin-top: 24px;
}
.static_contentList_subSection + .static_contentList_subSection {
  margin-top: 16px;
}
.globalResponsiveTable {
  position: relative;
  & > table {
    position: relative;
    width: 100%;
    border-spacing: 0px;
    border: 1px solid #000;
    border-collapse: collapse;
    & > thead {
      border-bottom: 1px solid #000;
      & > tr {
        & > th {
          position: relative;
          padding: 6px 12px;
          background-color: $grey-4;
          & + th {
            border-left: 1px solid #000;
          }
        }
      }
    }
    & > tbody {
      & > tr {
        & > td {
          position: relative;
          padding: 6px 12px;
          & > p {
            & > strong {
              display: inline;
              vertical-align: baseline;
            }
          }
          & + td {
            border-left: 1px solid #000;
          }
        }
        & + tr {
          border-top: 1px solid #000;
        }
      }
    }
  }
  & > i {
    display: block;
    font-size: 12px;
  }
}
@media only screen and (max-width: $device-max-mobile) {
  .static {
    &_pageTitle {
      font-size: 24px;
      line-height: 32px;
      margin: 24px auto;
      & > br {
        display: none;
      }
    }
    &_contentList {
      &_subSection {
        font-size: $font-std;
        & > ul {
          padding-left: 28px !important;
        }
      }
      &_section {
        font-size: $font-std;
      }
    }
    &_page {
      &_contentHolder {
        font-size: $font-std;
      }
      &_conditionsList {
        & > ul {
          padding-left: 16px;
          & > li {
            & > ul {
              &.isSubList {
                padding-left: 16px;
                & .eventDates {
                  & > ul {
                    padding-left: 16px;
                    & > li {
                      & > ul {
                        padding-left: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
