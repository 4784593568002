.rcToolTip.rc-tooltip {
  opacity: 1;
  font-size: 14px;
  width: 400px;
  max-width: 100%;
}

@media only screen and (max-width: $device-max-mobile) {
  .rcToolTip.rc-tooltip {
    width: 270px;
    .rc-tooltip-arrow {
      display: none;
    }
  }
}
