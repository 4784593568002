// TODO: SK, SR seperate all common form css
.applyToSpeak {
  padding: 0 30px 0 46px;
  line-height: 24px;
  background: url("../../../assets/img/SponsorFormGradient.svg") repeat-x;
  background-position-y: -120px;
  overflow-x: hidden;
  &--onIe {
    background: none !important;
    min-height: calc(100vh - 252px);
  }
  &_topSection {
    padding-top: 60px;
    max-width: 1200px;
    margin: 0 auto;
    h1 {
      position: relative;
      font-size: 36px;
      line-height: 44px;
      text-align: center;
      margin: 0px auto 20px;
      & > span {
        color: $titleHighlight;
      }
    }
    &_content {
      margin: 0 auto;
      > p {
        font-size: 16px;
        line-height: 26px;
        display: block;
        text-align: center;
      }
      > div {
        width: 35%;
        min-width: 300px; // max-width: 380px;
        > svg {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .submitted-message {
    overflow: visible !important;
    & > p {
      font-size: 24px;
      color: $success;
      line-height: 24px;
      text-align: center;
      margin: 30vh auto 40px;
      font-family: $font-bold;
    }
  }
  h2 {
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    font-weight: normal;
    font-family: $font-title;
    padding: 6px 0;
    margin: 50px auto 30px;
    background-color: rgba(133, 214, 255, 0.2);
    width: calc(100% + 76px);
    margin-left: -46px;
  }
  h3{
    font-size: x-large;
    text-align: center;
  }
  fieldset {
    margin-bottom: 10px !important;
  }
  .hs-submit {
    margin: 22px 0 40px;
  }

  .hs_proposed_role {
    margin-bottom: 20px !important;
  }
  .hs_sponsorship_elements {
    margin-bottom: 10px;
    > label {
      font-size: 16px;
      margin-bottom: 8px;
      display: inline-block;
      vertical-align: middle;
      font-family: $font-bold;
      em {
        font-family: $font-semi;
      }
    }
  }
  .hs-form-checkbox,
  .hs-form-booleancheckbox {
    display: inline-block;
    align-items: flex-start;
    margin-right: 24px;
  }
  .hs-main-font-element {
    strong {
      display: inline;
    }
  }
  .hs-sponsorship_elements,
  .hs_meeting_space_options {
    > label {
      margin-bottom: 10px;
      display: inline-block;
    }
    .hs-form-checkbox {
      margin-bottom: 8px;
    }
  }
  .hs_best_way_to_discuss_sponsorship_options {
    ul {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      > li {
        margin: 0px 28px 0 0;
      }
    }
  }
  .hs-form-field {
    > label {
      font-family: $font-bold;
      font-size: 16px;
      margin-bottom: 6px;
      display: block;
      em {
        font-family: $font-semi;
        font-style: normal;
      }
    }
  }
  .hs-main-font-element {
    font-family: $font-semi;
  }
  .joinMailListContent small {
    margin: 0 auto;
    max-width: 180px;
    display: block;
  }

  .joinMailListContent span {
    font-size: 22px;
    line-height: 24px;
    margin: 16px auto 20px;
    display: block;
  }

  .joinMailListContent{
    display: block;
  }

  .guidelines span{
    cursor: pointer;
    color: #0099fa;
    text-decoration: underline;
  }

  
}
@media only screen and (max-width: $device-max-ipad) {
  .applyToSpeak {
    padding: 0 50px;
    &_topSection {
      padding-top: 30px;
      h1 {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        margin: 10px auto 20px;
      }
      &_content {
        justify-content: center;
        flex-wrap: wrap;
        width: calc(100% - 60px);
        margin: 0 auto;
        > p {
          font-size: 16px;
          line-height: 26px;
        }
        > div {
          width: 50%;
          margin-top: 30px;
          max-width: 300px;
        }
      }
    }
    .submitted-message {
      & > p {
        font-size: 18px;
      }
    }
    h2 {
      font-size: 28px;
      line-height: 36px;
      margin: 24px auto 10px;
      width: calc(100% + 100px);
      margin-left: -50px;
    }
  }
  .form-columns-2,
  .form-columns-1,
  .form-columns-0,
  .form-columns-3 {
    .input {
      margin-right: 0 !important;
    }
    .field {
      width: 100% !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .applyToSpeak {
    padding: 0 15px;
    &_topSection {
      &_content {
        width: 100%;
      }
    }
    fieldset {
      margin-bottom: 0 !important;
    }
    h2 {
      margin: 40px auto 14px;
      width: calc(100% + 30px);
      margin-left: -15px;
      padding: 10px 30px;
    }
    .hs-form-checkbox,
    .hs-form-booleancheckbox {
      display: block;
    }
    .hs_exhibit_space_options {
      .hs-form-checkbox {
        width: 100%;
      }
    }
    .submitted-message {
      & > p {
        margin: 70px auto 50px;
      }
    }
  }
}
@media only screen and (min-width: 1300px) {
  .applyToSpeak {
    h2 {
      width: 100vw;
      margin-left: calc(((100vw - 1184px) * 0.5) * -1);
    }
  }
}
