$header-color: #114419;
$header-color-dark: #052b08;
$success: #51922c;
$text-color: #263271;
$anchor-color: #0099fa;
$border-color: #2d5e0d;
$warning: #fffdad;
$error: #ea3c3c;
$asterisk: #ae3033;
$disabled: #efefef;
$formElementBorder: #263271;
$primaryBtnBg: #114419;
$titleHighlight: #ef7a2b;
$modalBackdrop: rgba(0, 0, 0, 0.5);
$formElementBg: #fffce9;
$titleBg: rgba(133, 214, 255, 0.2);
$btnBgColor: #263271;
$white: #fff;
$red: #d20000;
$violetColor: #5c2b9b;
$listingBg: #114419;
$yellow: #f9f16d;
$black: #000000;
$grey-1: #757575;
$grey-2: #a5a5a5;
$grey-3: #bababa;
$grey-4: #efefef;
