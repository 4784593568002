.speakers {
  &_container {
    position: relative;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url($bucket_url + "/assets/img/speakers_bg.png");
  }
  &LogoWrapper {
    position: relative;
    margin-bottom: 68px;
  }
  &_topSection {
    position: relative;
    z-index: 8;
    padding: 50px 0px;
    &_content {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      &_left,
      &_right {
        flex: calc(100% - 250px);
        max-width: calc(100% - 250px);
        flex: -moz-calc(100% - 250px);
        flex: -webkit-calc(100% - 250px);
        max-width: -moz-calc(100% - 250px);
        max-width: -webkit-calc(100% - 250px);
      }
      &_right {
        flex: 250px 0;
        max-width: 250px;
        & > a {
          color: #fff;
          display: block;
          background-color: $btnBgColor;
          & + a {
            margin-top: 16px;
            background-color: $primaryBtnBg;
          }
        }
      }
      &_left {
        padding-right: 48px;
        & > p {
          font-size: 16px;
          line-height: 24px;
          font-family: $font-regular;
          & + p {
            margin-top: 16px;
          }
        }
      }
    }
  }
  &_wrapper {
    position: relative;
    &_title {
      position: relative;
      text-align: center;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #263271;
      & > span {
        position: relative;
        font-size: 48px;
        padding: 0px 44px;
        line-height: 38px;
        @include inlineViewMiddle;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 0px;
          width: 32px;
          height: 7px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: left center;
          transform: scaleX(-1) translateY(-50%);
          -moz-transform: scaleX(-1) translateY(-50%);
          -webkit-transform: scaleX(-1) translateY(-50%);
        }
        &::after {
          left: auto;
          right: 0px;
          transform: scaleX(1) translateY(-50%);
          -moz-transform: scaleX(1) translateY(-50%);
          -webkit-transform: scaleX(1) translateY(-50%);
        }
      }
    }
  }
  &_list {
    position: relative;
    & > ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0px -12px;
      align-items: flex-start;
      justify-content: center;
      & > li {
        flex: 20%;
        max-width: 20%;
        padding: 0px 12px;
        margin-bottom: 36px;
      }
    }
  }
  &_card {
    position: relative;
    cursor: pointer;
    text-align: center;
    @include transition(0.5s);
    &:hover {
      @include transformScale(1.1);
    }
    &_img {
      position: relative;
      width: 200px;
      height: 200px;
      display: flex;
      margin: 0px auto;
      overflow: hidden;
      border-radius: 50%;
      align-items: center;
      border: 1px solid #020200;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      & > svg {
        @include positionAbsoluteTop;
        top: 50%;
        width: 120px;
        height: 120px;
        color: $grey-3;
        margin: 0px auto;
        max-width: 120px;
        @include transformTranslateY(-50%);
      }
    }
    &_details {
      position: relative;
      margin-top: 12px;
    }
    &_speakerName,
    &_speakerType {
      display: block;
      font-size: 16px;
      line-height: 24px;
    }
    &_speakerType {
      color: #666;
      font-size: 12px;
      line-height: 18px;
    }
  }
  &_individual {
    position: relative;
    display: flex;
    margin-bottom: 62px;
    align-items: flex-start;
    &_wrapper {
      position: relative;
      padding: 50px 0px 0px;
    }
    &_img {
      flex: 312px;
      max-width: 312px;
      margin-right: 56px;
      & > span {
        width: 312px;
        height: 312px;
        display: flex;
        overflow: hidden;
        border-radius: 50%;
        align-items: center;
        background-color: $white;
        border: 1px solid #020200;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        & > svg {
          width: 120px;
          height: auto;
          margin: 0px auto;
          color: #bababa;
        }
      }
    }
    &_description {
      flex: calc(100% - 312px);
      flex: -moz-calc(100% - 312px);
      max-width: calc(100% - 312px);
      flex: -webkit-calc(100% - 312px);
      max-width: -moz-calc(100% - 312px);
      max-width: -webkit-calc(100% - 312px);
    }
    &_details {
      position: relative;
      margin-bottom: 32px;
      & > p,
      & > b,
      & > strong {
        position: relative;
        display: block;
        font-family: $font-title;
      }
      & > strong {
        font-size: 36px;
        line-height: 44px;
        color: $titleHighlight;
      }
      & > p {
        font-size: 28px;
        line-height: 36px;
        font-family: $font-brandon-regular;
      }
      & > b {
        font-size: 32px;
        line-height: 40px;
      }
    }
    &_desc {
      position: relative;
      & > p {
        position: relative;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 36px;
      }
      & > .global_btn {
        padding: 12px 36px;
        background-color: $btnBgColor;
      }
    }
  }
  &_notAvailable {
    @include positionFixed;
    top: 50%;
    text-align: center;
    margin: -112px auto 0px;
    & > b {
      display: block;
      line-height: 32px;
      font-size: $font-lg;
      margin-bottom: 24px;
    }
  }
}
.flipSpeakerContent {
  flex-direction: row-reverse;
  .speakers_topSection_content_left {
    padding-left: 48px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: $device-max-mobile) {
  .speakers {
    &LogoWrapper {
      padding-top: 0px;
      margin-bottom: 24px;
    }
    &_topSection {
      position: relative;
      top: 0px;
      padding: 24px 0px;
      border-bottom: none;
      padding: 12px 0px 0px;
      .pageTitle {
        font-size: 32px;
        line-height: 36px;
        margin: 0px auto 16px;
      }
      &_content {
        display: block;
        &_left,
        &_right {
          flex: none;
          padding: 0px;
          max-width: none;
          text-align: center;
        }
        &_left {
          margin-bottom: 24px;
        }
        &_right {
          left: 0px;
          right: 0px;
          margin: 24px auto 12px;
          &.isSticky {
            position: fixed;
            top: 120px;
            z-index: 4;
            padding: 12px;
            margin: 0px auto;
            background-color: #fff;
            display: block !important;
            border-bottom: 2px solid #000;
          }
        }
      }
    }
    &_wrapper {
      margin-top: 24px;
      &_title {
        & > span {
          font-size: 32px;
        }
      }
    }
    &_list {
      & > ul {
        margin: 0px -6px;
        & > li {
          flex: 50%;
          max-width: 50%;
          padding: 0px 6px;
          margin-bottom: 24px;
        }
      }
    }
    &_card {
      &:hover {
        @include transformScale(1);
      }
      &_img {
        width: 120px;
        height: 120px;
        & > svg {
          width: 80px;
          height: 80px;
        }
      }
    }
    &_individual {
      display: block;
      &_wrapper {
        padding: 36px 0px 0px;
      }
      &_img {
        flex: none;
        max-width: none;
        margin: 0px auto 24px;
        & > span {
          width: 220px;
          height: 220px;
          margin: 0px auto;
        }
      }
      &_description {
        max-width: none;
        text-align: center;
      }
      &_desc {
        & > p {
          margin-bottom: 24px;
        }
      }
    }
    &_notAvailable {
      position: relative;
      top: 0px;
      margin: 90px auto;
      & > b {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) {
  .speakers {
    &_topSection {
      &_content {
        &_right {
          & > a {
            padding: 14px 12px;
          }
        }
      }
    }
    &_list {
      & > ul {
        margin: 0px -12px;
        & > li {
          flex: 25%;
          max-width: 25%;
          padding: 0px 12px;
        }
      }
    }
    &_individual {
      display: block;
      &_img {
        flex: none;
        max-width: none;
        margin: 0px auto 24px;
        & > span {
          width: 220px;
          height: 220px;
          margin: 0px auto;
        }
      }
      &_description {
        max-width: none;
        text-align: center;
      }
      &_desc {
        & > p {
          margin-bottom: 24px;
        }
      }
    }
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) and (orientation: portrait) {
  .speakers {
    &_wrapper {
      &_title {
        & > span {
          font-size: 36px;
        }
      }
    }
    &_topSection {
      &_content {
        &_left {
          padding-right: 24px;
          flex: calc(100% - 230px) 1;
          max-width: calc(100% - 230px);
          flex: -moz-calc(100% - 230px) 1;
          max-width: -moz-calc(100% - 230px);
          flex: -webkit-calc(100% - 230px) 1;
          max-width: -webkit-calc(100% - 230px);
        }
        &_right {
          flex: 230px 0;
          max-width: 230px;
        }
      }
    }
    &_list {
      & > ul {
        margin: 0px -12px;
        & > li {
          flex: 33.33%;
          max-width: 33.33%;
          padding: 0px 12px;
        }
      }
    }
  }
}
@media only screen and (min-width: 790px) and (max-width: 990px) {
  .speakers {
    &_wrapper {
      &_title {
        & > span {
          font-size: 36px;
        }
      }
    }
    &_topSection {
      &_content {
        &_left {
          padding-right: 24px;
          flex: calc(100% - 230px) 1;
          max-width: calc(100% - 230px);
          flex: -moz-calc(100% - 230px) 1;
          max-width: -moz-calc(100% - 230px);
          flex: -webkit-calc(100% - 230px) 1;
          max-width: -webkit-calc(100% - 230px);
        }
        &_right {
          flex: 230px 0;
          max-width: 230px;
        }
      }
    }
    &_list {
      & > ul {
        margin: 0px -12px;
        & > li {
          flex: 33.33%;
          max-width: 33.33%;
          padding: 0px 12px;
        }
      }
    }
  }
}
@media only screen and (min-width: $device-desktop) and (max-width: 1180px) {
  .speakers {
    &_list {
      & > ul {
        margin: 0px -10px;
        & > li {
          padding: 0px 10px;
          margin-bottom: 24px;
        }
      }
    }
    &_card {
      &_img {
        width: 170px;
        height: 170px;
      }
    }
  }
}
