.global_section {
  &_tickets {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    background-image: url($bucket_url + "/assets/img/ticketSection_bg.png");
    &--dynamicSponsorTickets {
      &.hasOrgLogo {
        .global_section_tickets_image {
          top: 50px;
        }
      }
      .global_section_tickets_content {
        align-items: flex-start !important;
      }
      .global_section_tickets_image {
        height: 120px;
        &.noSponsorOrgLogo {
          height: 220px;
          top: 0px !important;
        }
        & > img {
          width: 100%;
          height: 100%;
          margin: 0px auto;
          object-fit: contain;
        }
      }
    }
    &_noTicketsFound {
      position: relative;
      color: $grey-1;
      font-size: 16px;
      max-width: 850px;
      padding: 24px 36px;
      text-align: center;
      margin: 36px auto 0px;
      border: 2px dashed $grey-3;
      & > svg {
        width: 50%;
        height: 50px;
        display: block;
        margin: 0px auto 12px;
      }
    }
    .global_section_title {
      margin-top: 12px;
    }
    .global_section_tickets_content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_wrapper {
        max-width: calc(100% - 400px);
      }
      .global_section_tickets_image {
        position: relative;
        width: 350px;
        font-size: 10px;
        text-align: center;
      }
    }
    &_content_wrapper {
      position: relative;
      & > strong {
        display: block;
        font-size: 40px;
        line-height: 48px;
        font-weight: normal;
        margin-bottom: 32px;
        font-family: $font-title;
      }
      & p {
        font-size: 18px;
        margin: 0px auto 16px;
        line-height: 24px;
        & + p {
          margin-bottom: 0px;
        }
        strong {
          display: inline;
          font-size: inherit;
        }
        a {
          display: inline;
          vertical-align: baseline;
          text-decoration: underline;
        }
      }
      &_desc {
        position: relative;
      }
    }
    &_list {
      position: relative;
      margin: 44px auto 0px;
      & > ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0px -18px;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        & > li {
          flex: 25%;
          max-width: 25%;
          padding: 0px 18px;
          margin-bottom: 36px;
          &.bankFree,
          &.retailerFree {
            flex: 50%;
            max-width: 50%;
            & > .tickets_box {
              margin: 0px auto;
              max-width: 490px;
              margin-right: 0px;
            }
          }
          &.retailerFree {
            & > .tickets_box {
              margin-left: 0px;
            }
          }
        }
      }
    }
    &--dynamicSponsorTickets {
      .global_section_tickets_list > {
        ul.hostedList {
          justify-content: center;
          > li {
            min-width: unset;
            flex: unset;
            > .tickets_box {
              margin: 0 auto;
            }
          }
        }
      }
      .global_section_tickets_list {
        margin-top: 60px;
        .tickets_box {
          min-height: 380px;
          display: flex;
          flex-direction: column;
          .tickets_box_body {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
        .hostedList .tickets_box {
          min-height: unset;
          display: block;
          .tickets_box_body {
            display: block;
          }
        }
      }
    }
  }
}
.ticket {
  &_card {
    position: relative;
    height: 100%;
    overflow: hidden;
    text-align: center;
    border-radius: 16px;
    background-color: $white;
    border: 2px solid $text-color;
    &_head {
      position: relative;
      width: 100%;
      height: 66px;
      padding: 10px;
      overflow: hidden;
      @include displayFlexCenter;
      border-bottom: 2px solid $text-color;
      &_content {
        position: relative;
        width: 100%;
        font-size: 0px;
        &.hasTermsAndConditions {
          & > strong {
            margin-right: 8px;
          }
          & > .ticketsConditionTooltip {
            position: relative;
            top: -1px;
            z-index: 2;
            font-size: 18px;
            cursor: pointer;
            border-radius: 50%;
            background-color: $white;
            .retailerPaid & {
              vertical-align: bottom;
            }
            .tickets_box--macOs & {
              max-width: 18px;
              max-height: 18px;
            }
            & > svg {
              display: block;
              fill: $text-color;
            }
          }
        }
        & > strong,
        & > .ticketsConditionTooltip {
          position: relative;
          font-size: 16px;
          @include inlineViewMiddle;
        }
        & > strong {
          line-height: 20px;
          font-weight: normal;
          font-family: $font-title;
        }
      }
    }
    &_body {
      position: relative;
      z-index: 2;
      padding: 20px 12px 80px;
      //.media &,
      .employeesInTransition & {
        @include positionAbsoluteTop;
        top: 50%;
        padding: 0px 12px;
        @include transformTranslateY(-50%);
      }
      & > span {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
      }
      & > strong {
        font-size: 76px;
        line-height: 60px;
        font-weight: normal;
        letter-spacing: 1px;
        font-family: $font-bebas-neue;
      }
    }
    &_priceDetails {
      position: relative;
      &_actualPrice,
      &_discountedPrice {
        font-size: 76px;
        line-height: 60px;
        font-weight: normal;
        letter-spacing: 1px;
        font-family: $font-bebas-neue;
      }
      &_discountPercentage {
        font-size: 15px;
        line-height: 20px;
        margin: 12px auto;
        &:last-child {
          margin-bottom: 0px;
        }
        & a,
        & strong {
          display: inline;
          vertical-align: baseline;
        }
        & a {
          text-decoration: underline;
        }
        & span {
          font-family: $font-bold;
        }
      }
      &_actualPrice {
        color: #d3d3d3;
        &.noDiscount{
          color: $text-color;
        }
      }
    }
    &_description {
      font-size: 15px;
      line-height: 20px;
      margin: 12px auto;
      &:last-child {
        margin-bottom: 0px;
      }
      & a,
      & strong {
        display: inline;
        vertical-align: baseline;
      }
      & a {
        text-decoration: underline;
      }
    }
    &_footer {
      @include positionAbsoluteBottomLeft;
      right: 0px;
      bottom: 12px;
      padding: 0px 12px;
      & > a,
      & > span {
        position: relative;
        display: block;
        cursor: pointer;
        font-size: 22px;
        padding: 8px 0px;
        line-height: 24px;
        color: $text-color;
        border-radius: 8px;
        font-family: $font-title;
        border: 2px solid $text-color;
      }
    }
  }
}
.sBrand_logo {
  position: relative;
  padding-top: 32px;
  text-align: center;
  margin: 32px auto 68px;
}
.sBrand_logo > img {
  max-width: 500px;
  max-height: 100px;
}

li.bulkTickets .ticket_card_description{
  padding-top: 25%;
}

@media only screen and (max-width: $device-max-mobile) {
  .global {
    &_section {
      &_tickets {
        background-size: auto;
        & > .container {
          &:first-child {
            &::after {
              top: 0px;
              width: 160px;
              height: 140px;
            }
          }
        }
        &_content {
          flex-wrap: wrap;
          > b {
            margin-bottom: 24px;
          }
          & > p {
            line-height: 22px;
            font-size: $font-md;
          }
          &_wrapper {
            margin: 24px auto 0px !important;
            &_desc {
              & > p {
                max-width: none;
                font-size: 16px;
                margin: 0px auto;
                line-height: 22px;
              }
            }
          }
        }
        &_list {
          margin: 32px auto 8px;
          & > ul {
            display: block;
            & > li {
              display: block;
              flex: none !important;
              max-width: none !important;
              &.bankFree,
              &.retailerFree {
                .tickets_box {
                  max-width: none;
                }
              }
            }
          }
          &_short {
            & .tickets_box {
              max-height: none;
            }
          }
        }
        &--dynamicSponsorTickets {
          .global_section_tickets_list {
            .tickets_box {
              min-height: initial;
            }
          }
        }
      }
    }
  }
  .tickets_box_body {
    & > span:empty {
      display: none;
    }
    & > p:last-child {
      margin: 0px auto;
    }
  }
  .sBrand_logo > img {
    max-width: 100%;
  }
  .global_section_tickets {
    .global_section_tickets_content {
      .global_section_tickets_image {
        top: 0px;
        height: auto;
        margin-left: 0px !important;
        max-width: 350px !important;
      }
    }
  }

  li.bulkTickets .ticket_card_description{
    padding-top: 0.20%;
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) {
  .global {
    &_section {
      &_tickets {
        &_list {
          > ul {
            > li {
              flex: 33.33% !important;
              max-width: 33.33% !important;
              &.bankFree,
              &.retailerFree {
                .tickets_box {
                  max-width: none;
                }
              }
            }
          }
        }
        & > .container {
          &:first-child {
            &::after {
              width: 260px;
              height: 240px;
            }
          }
        }
      }
    }
  }
  .ticket {
    &_card {
      &_head {
        &_content {
          & > strong {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) and (orientation: portrait) {
  .global {
    &_section {
      &_tickets {
        & > .container {
          &:first-child {
            &::after {
              top: 0px;
              right: 12px;
              width: 200px;
              height: 180px;
            }
          }
        }
        &_list {
          > ul {
            > li {
              flex: 50% !important;
              max-width: 50% !important;
            }
          }
        }
        &_content {
          &_wrapper {
            &_desc {
              & > p {
                max-width: none;
                font-size: 16px;
                margin: 0px auto;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
}
.ticketErrorMessage {
  font-size: 18px;
  padding: 100px 20px;
  text-align: center;
  a {
    display: inline;
    vertical-align: unset;
  }
}
@media only screen and (min-width: 790px) and (max-width: $device-max-ipad-potrait) {
  .global {
    &_section {
      &_tickets {
        &_list {
          & > .container {
            &:first-child {
              &::after {
                top: 0px;
                right: 12px;
                width: 200px;
                height: 180px;
              }
            }
          }
          > ul {
            > li {
              flex: 50% !important;
              max-width: 50% !important;
            }
          }
        }
        &_content {
          &_wrapper {
            &_desc {
              & > p {
                max-width: none;
                font-size: 16px;
                margin: 0px auto;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $device-max-ipad) {
  .global_section_tickets {
    &--dynamicSponsorTickets {
      &.hasOrgLogo {
        .global_section_tickets_image {
          top: 4px;
        }
      }
    }
    .global_section_tickets_content {
      justify-content: center;
      .global_section_tickets_content_wrapper {
        order: 2;
        width: 100%;
        max-width: none;
        margin-left: 24px;
        > strong {
          font-size: 30px;
          line-height: 34px;
          margin-bottom: 8px;
        }
      }
      .global_section_tickets_image {
        width: 100%;
        max-width: 250px;
      }
    }
    &--dynamicSponsorTickets {
      .global_section_tickets_list {
        margin-top: 60px;
      }
    }
    .global_section_tickets_list > ul {
      margin: 0px -12px;
      & > li {
        padding: 0px 12px;
        margin-bottom: 24px;
      }
    }
  }
}
@media only screen and (min-width: $device-desktop) and (max-width: 1280px) {
  .global {
    &_section {
      &_tickets {
        &_list {
          > ul {
            > li {
              flex: 33.33% !important;
              max-width: 33.33% !important;
              &.bankFree,
              &.retailerFree {
                .tickets_box {
                  max-width: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .ticket_card_body {
    //.media &,
    .employeesInTransition & {
      position: relative;
      top: 0px;
      padding: 12px 12px 80px;
      @include transformTranslateY(0px);
    }
  }
}
