.bright{
    color: #124419;
}


div {
    display: block;
    unicode-bidi: isolate;
}

p {
    margin-bottom: 1rem;
    margin-top: 0;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

mark{
    color: red;
    background-color: transparent;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto, .col-xs, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-auto, .col-xsm, .col-xsm-1, .col-xsm-10, .col-xsm-11, .col-xsm-12, .col-xsm-2, .col-xsm-3, .col-xsm-4, .col-xsm-5, .col-xsm-6, .col-xsm-7, .col-xsm-8, .col-xsm-9, .col-xsm-auto, .col-xxl, .col-xxl-1, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-auto, .col-xxxl, .col-xxxl-1, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-auto, .col-xxxxl, .col-xxxxl-1, .col-xxxxl-10, .col-xxxxl-11, .col-xxxxl-12, .col-xxxxl-2, .col-xxxxl-3, .col-xxxxl-4, .col-xxxxl-5, .col-xxxxl-6, .col-xxxxl-7, .col-xxxxl-8, .col-xxxxl-9, .col-xxxxl-auto {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
}



.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}



.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}


.col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-xl {
    -ms-flex-preferred-size: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}


 .session-time p {
    //color: #037e7b;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 1.2em;
}

 .session-body p {
    //color: #037e7b;
    //font-weight: bold;
    margin-bottom: 0;
    font-size: 1em;
}

.day .day-title {
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 3em;
}

 .session:first-child {
    border-top: 1px dashed #d4d4d4;
}

 .session {
    border-bottom: 1px dashed #d4d4d4;
    padding: 1em 0;
}

 .track {
    padding-bottom: 1em;
}

.session-body {
    margin-top: .5em;
}

 .track .header {
    color: #fff;
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 0 1em;
}



.track {
    color: #000;
    padding-left: 1px;
    padding-right: 1px;
}

.track-header {
    background-color: #000000;
    color: #fff;
    min-height: 110px;
}

.track-header h3 {
    font-size: 1.2em;
    font-style: normal;
    font-weight: 700;
    padding: .25em 1em;
    text-transform: uppercase;
    text-align: center;
    color: #000;
}

.track-header h4 {
    font-size: 1.2em;
    font-style: normal;
    font-weight: 700;
    padding: .25em 1em;
    text-transform: uppercase;
    background-color: #000000;
}

.track-header h5 {
    font-weight: 700;
    padding: .25em 1em;
    background-color: #000000;
    font-size: larger;
}

.track-header p {
    font-weight: 600;
    margin-bottom: 0;
    padding: 0 1em;
}

.track-subheading {
    font-size: .85em;
    font-weight: 800;
    font-family: "montserrat_bold", sans-serif;
    padding: 1em 1em;
    margin: 0;
}

.row.tracks {
    display: flex;
    align-items: stretch; /* This makes all flex items the same height */
}

p.track-speakers{
    font-size: smaller;
}

.track-speakers a{
    vertical-align: inherit;
}



.dominating .track-header h4,.dominating .track-header h5 {
    background-color: #E45900;
}
.dominating .track-header {
    background-color: #FF9F61;
}
.dominating .track-subheading {
    background-color: #EFC6AC;
}
.dominating .track-content {
    background-color: #EFC6AC;
}
.track.dominating {
    background: #EFC6AC;
}

.track3 .track-header h4,.track3 .track-header h5 {
    background-color: #E45900;
}
.track3 .track-header {
    background-color: #FF9F61;
}
.track3 .track-subheading {
    background-color: #EFC6AC;
}
.track3 .track-content {
    background-color: #EFC6AC;
}
.track.track3 {
    background: #EFC6AC;
}


.sponsored .track-header h4,.sponsored .track-header h5 {
    background-color: #1DC2FF;
}
.sponsored .track-header {
    background-color: #73D9FF;
}
.sponsored .track-subheading {
    background-color: #D0F2FF;
}
.sponsored .track-content {
    background-color: #D0F2FF;
}
.track.sponsored {
    background: #D0F2FF;
}


.value .track-header h4,.value .track-header h5 {
    background-color: #00E677;
}
.value .track-header {
    background-color: #68E8AA;
}
.value .track-subheading {
    background-color: #B2EDD0;
}
.value .track-content {
    background-color: #B2EDD0;
}
.track.value {
    background: #B2EDD0;
}

.track2 .track-header h4,.track2 .track-header h5 {
    background-color: #00E677;
}
.track2 .track-header {
    background-color: #68E8AA;
}
.track2 .track-subheading {
    background-color: #B2EDD0;
}
.track2 .track-content {
    background-color: #B2EDD0;
}
.track.track2 {
    background: #B2EDD0;
}



.navigating .track-header h4,.navigating .track-header h5 {
    background-color: #AF28D6;
}
.navigating .track-header {
    background-color: #E384FF;
}
.navigating .track-subheading {
    background-color: #DEB9E8;
}
.navigating .track-content {
    background-color: #DEB9E8;
}
.track.navigating {
    background: #DEB9E8;
}

.general .track-header h4,.general .track-header h5 {
    background-color: #AF28D6;
}
.general .track-header {
    background-color: #E384FF;
}
.general .track-subheading {
    background-color: #DEB9E8;
}
.general .track-content {
    background-color: #DEB9E8;
}
.track.general {
    background: #DEB9E8;
}


.building .track-header h4,.building .track-header h5 {
    background-color: #1460D1;
}
.building .track-header {
    background-color: #64A2FF;
}
.building .track-subheading {
    background-color: #B4CAEB;
}
.building .track-content {
    background-color:#B4CAEB;
}
.track.building {
    background: #B4CAEB;
}

.track1 .track-header h4,.track1 .track-header h5 {
    background-color: #1460D1;
}
.track1 .track-header {
    background-color: #64A2FF;
}
.track1 .track-subheading {
    background-color: #B4CAEB;
}
.track1 .track-content {
    background-color: #B4CAEB;
}
.track.track1 {
    background: #B4CAEB;
}


.financing .track-header h4,.financing .track-header h5 {
    background-color: #04B201;
}
.financing .track-header {
    background-color: #81FA72;
}
.financing .track-subheading {
    background-color: #B7F6AF;
}
.financing .track-content {
    background-color: #B7F6AF;
}
.track.financing {
    background: #B7F6AF;
}

.track4 .track-header h4,.track4 .track-header h5 {
    background-color: #04B201;
}
.track4 .track-header {
    background-color: #81FA72;
}
.track4 .track-subheading {
    background-color: #B7F6AF;
}
.track4 .track-content {
    background-color: #B7F6AF;
}
.track.track4 {
    background: #B7F6AF;
}


.keynotes .track-header h4,.keynotes .track-header h5 {
    background-color: #F41E98;
}
.keynotes .track-header {
    background-color: #FF6DC0;
}
.keynotes .track-subheading {
    background-color: #E6A8CB;
}
.keynotes .track-content {
    background-color: #E6A8CB;
}
.track.keynotes {
    background: #E6A8CB;
}


.registration .track-header h4,.registration .track-header h5 {
    background-color: #999;
}
.registration .track-header {
    background-color: #D8D8D8;
}
.track.registration {
    background: #D8D8D8;
}



.tabletalks .track-header h4,.tabletalks .track-header h5 {
    background-color: #FFD21C;
}
.tabletalks .track-header {
    background-color: #FFE169;
    color: #000;
}
.tabletalks .track-subheading {
    background-color: #FFE169;
}
.track.tabletalks {
    background: #FFE169;
}


.party .track-header h4,.party .track-header h5 {
    background-color: #999;
}
.party .track-header {
    background-color: #D8D8D8;
}
.track.party {
    background: #D8D8D8;
}



div.description-button{
    text-align: center;
}

.description-button span{
    // position: relative;
    // display: block;
    cursor: pointer;
    //margin-left: 1em;
    //font-size: 22px;

    line-height: 3em;

    font-size: smaller;
    font-family: $font-bold;
    padding: 0.4em 1em 0.3em 1em;
    // line-height: 24px;
    color: #263271;
    border-radius: 12px;
    //font-family: "brandon_grotesque", sans-serif;
    border: 2px solid #263271;
    //background-color: #faf4a8;
  }



.track .header h4 {
    color: #fff;
    font-size: .7em;
    font-style: normal;
    font-weight: 700;
    padding: .85em 1em;
    text-transform: uppercase;
}



// .agenda-content .row.tracks .track.navigating {background-color: #b4a7d6;}
 

 
// .agenda-content .row.tracks .track.building {
//     background: #a4c2f4;
// }
 
// .agenda-content .row.tracks .track.dominating {
//     background-color: #f9cb9c;
// }
 
// .agenda-content .row.tracks .track.financing {
//     background-color: #a2c4c9;
// }
 
.agenda-content .row.tracks .col-xl {
    width: 32.9999%;
    flex-basis: auto;
    padding-left: 0;
    padding-right: 0;
}
 
.agenda-content .row.tracks {
    gap: .5px;
}
 
// .agenda-content .row.tracks .track.sponsored {
//     background-color: #cfe2f3;
// }
 
.track-header h4, .track-header h5 {
    min-height: 34px;
    padding-top: 0;
    padding-bottom: 0;
}
 
.track-header {
    min-height: 127px;
}
 
.track-header h3 {
    display: flex;
    align-items: center;
    height: 57px;
}
 
.track-header h4 {
    padding-top: 10px;
}
 
.track-header h5 {
    padding-bottom: 10px;
}
 
div.description-button {
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (min-width: 768px) {
    .track .header p {
        margin-bottom: 0;
    }
    .content {
        letter-spacing: .01em;
    }
}

@media (min-width: 992px) {
     .track .header p {
        font-size: .95em;
    }

    .content {
        font-size: .9em;
    }
}

.track .header p {
    font-size: .95em;
}


 .track .track-content {
    padding-bottom: 1em;
}

.track .track-content p {
    padding-left: 1em;
    padding-right: 1em;
}

.track .track-content strong {
    display: inline;
}


 
.session {
    padding: 1.4em 0;
}

.row.session {
    margin-left: 0;
    margin-right: 0;
}
 
@media only screen and (min-width: 768px) and (max-width: 1200px){
    .agenda-content .row.tracks .col-xl {
        width: 49%;
    }
}

@media only screen and (max-width: 767px){
    .session .col-12.session-time {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

// @media only screen and (min-width: 768px) and (max-width: 1350px) {
 
//   .session-body .tracks {
//         width: 1000px;
//     }
 
//     .session-body {
//         overflow-x: scroll;
//     }
 
//     .session-body::-webkit-scrollbar:horizontal {
//         height: 8px;
//     }
 
//     .session-body::-webkit-scrollbar-thumb {
//         border-radius: 8px;
//         background-color: #999;
//     }
 
//     .session-body::-webkit-scrollbar {
//         -webkit-appearance: none;
//     }
 
//     .track .track-content {
//         padding-bottom: 0;
//     }
 
//     .agenda-content .row.tracks .track.keynotes {
//         background-color: #ead1dc;
//     }
 
//     .agenda-content .row.tracks .track.tabletalks {
//         background-color: #ffe599;
//     }
// }



@media only screen and (max-width: 767px) {
    .agenda-content .session-body {
       flex: 0 0 100%;
       max-width: 100%;
   }

   .agenda-content .row.tracks .col-xl {
       width: 100%;
   }

}

.subsection-title {
    font-size: 1.5em;
    font-family: "Montserrat", "Inter", "Montserrat", "Open Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-variant: normal;
    font-weight: 800;
    letter-spacing: .02em;
    margin-bottom: 1em;
    text-align: center;
}

  @media only screen and (max-width: $device-max-mobile) {
    .static {
      &_pageTitle {
        font-size: 24px;
        line-height: 32px;
        margin: 24px auto;
        & > br {
          display: none;
        }
      }
      &_contentList {
        &_subSection {
          font-size: $font-std;
          & > ul {
            padding-left: 28px !important;
          }
        }
        &_section {
          font-size: $font-std;
        }
      }
      &_page {
        &_contentHolder {
          font-size: $font-std;
        }
        &_conditionsList {
          & > ul {
            padding-left: 16px;
            & > li {
              & > ul {
                &.isSubList {
                  padding-left: 16px;
                  & .eventDates {
                    & > ul {
                      padding-left: 16px;
                      & > li {
                        & > ul {
                          padding-left: 20px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  