body {
  &.isWindows {
    &.hasAlertBanner {
      padding: 150px 0px 0px;
    }
  }
  &.hasAlertBanner {
    padding: 140px 0px 0px;
    .header {
      top: 48px;
    }
  }
}
.alert_banner {
  @include positionFixed;
  z-index: 96;
  font-size: 22px;
  color: #f11908;
  line-height: 32px;
  padding: 8px 12px;
  text-transform: uppercase;
  background-color: #ffff27;
  & > b {
    & > br {
      display: none;
    }
  }
}
@media only screen and (max-width: $device-max-mobile) {
  body {
    &.hasAlertBanner {
      padding: 138px 0px 0px;
      .header {
        top: 70px;
      }
      .header_navLinks_bottom {
        top: 138px;
        height: calc(100% - 138px);
        height: -moz-calc(100% - 138px);
        height: -webkit-calc(100% - 138px);
      }
    }
  }
  .alert_banner {
    font-size: 14px;
    line-height: 18px;
    padding: 8px 10px;
    & > b {
      & > br {
        display: inline;
      }
    }
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) {
  body {
    &.hasAlertBanner {
      padding: 202px 0px 0px;
    }
  }
  .alert_banner {
    padding: 8px;
    font-size: 12px;
  }
}
@media only screen and (min-width: 380px) and (max-width: 647px) {
  body {
    &.hasAlertBanner {
      padding: 152px 0px 0px;
      .header {
        top: 82px;
      }
      .header_navLinks_bottom {
        top: 152px;
        height: calc(100% - 152px);
        height: -moz-calc(100% - 152px);
        height: -webkit-calc(100% - 152px);
      }
    }
  }
  .alert_banner {
    font-size: 16px;
    line-height: 22px;
    & > b {
      max-width: 380px;
    }
  }
}
@media only screen and (min-width: 648px) and (max-width: $device-max-mobile) {
  body {
    &.hasAlertBanner {
      padding: 138px 0px 0px;
      .header {
        top: 68px;
      }
    }
  }
  .alert_banner {
    font-size: 20px;
    line-height: 26px;
  }
}
@media only screen and (min-width: $device-desktop) and (max-width: 1200px) {
  .alert_banner {
    font-size: 17px;
  }
}
