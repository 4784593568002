.hotel {
  &_content {
    position: relative;
    margin: 32px auto;
    &_wrapper,
    &_img {
      position: relative;
    }
    & > h1,
    & > h2 {
      font-size: 32px;
      line-height: 44px;
      margin: 0px auto 24px;
      & > span {
        color: $titleHighlight;
      }
    }
    & > p {
      font-size: 16px;
      line-height: 24px;
      & + p {
        margin-top: 16px;
      }
      & a,
      & > b {
        display: inline;
        vertical-align: baseline;
        text-decoration: underline;
      }
      & > b {
        text-decoration: none;
      }
      & > i {
        display: block;
        font-size: 12px;
      }
    }
    &_btn {
      margin-top: 32px;
    }
  }
}
@media only screen and (max-width: $device-max-mobile) {
  .hotel {
    &_content {
      margin: 24px auto 32px;
      & > h1 {
        font-size: 28px;
        line-height: 28px;
        margin: 0px auto 24px;
      }
      &_btn {
        margin-top: 24px;
      }
    }
  }
}
