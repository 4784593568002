.footer {
  position: relative;
  padding: 12px 0px;
  background-color: $header_color;
  .isMobilePrivacy & {
    display: none;
  }
  &_container {
    position: relative;
    font-size: 0px;
  }
  &_links,
  &_logo {
    position: relative;
    width: 360px;
    font-size: $font-std;
    @include inlineViewMiddle;
  }
  &_links {
    text-align: right;
    width: calc(100% - 360px);
    width: -moz-calc(100% - 360px);
    width: -webkit-calc(100% - 360px);
    & > ul {
      & > li {
        & > a {
          color: #fff;
          font-size: $font-md;
          line-height: $lineheight-font-sm;
          &:hover {
            text-decoration: underline;
          }
        }
        & + li {
          margin-top: 8px;
        }
      }
    }
  }
  &_logo {
    text-align: center;
    & > a {
      @include inlineViewMiddle;
      & > svg {
        width: 300px;
        height: 60px;
        display: block;
      }
    }
  }
  &_contactDetails {
    position: relative;
    text-align: center;
    margin: 16px -12px 0px;
    & > ul {
      position: relative;
      margin: 0px auto;
      @include inlineViewMiddle;
      & > li {
        position: relative;
        color: #fff;
        & + li {
          &::before {
            content: "";
            @include positionAbsoluteTopLeft;
            top: 50%;
            left: -2px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #fff;
            @include transformTranslateY(-50%);
          }
          & > a,
          & > span {
            color: #fff;
            display: block;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        & .socialMedia {
          display: block;
        }
      }
      & + ul {
        & > li {
          & + li {
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $device-max-mobile) {
  .footer {
    padding: 20px 0px;
    &_logo,
    &_links {
      width: 100%;
      display: inline-block;
    }
    &_logo {
      & > a {
        & > svg {
          width: 220px;
          height: auto;
        }
      }
    }
    &_links {
      margin-top: 12px;
      text-align: center;
      & > ul {
        & > li {
          & > a {
            font-size: 14px;
            line-height: normal;
          }
        }
      }
    }
    &_contactDetails {
      margin: 12px auto 0px;
      & > ul {
        display: block;
        margin: 0px auto;
        & > li {
          padding: 0px;
          display: block;
          & + li {
            margin-top: 8px;
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1155px) {
  .footer {
    &_contactDetails {
      & > ul {
        & + ul {
          margin-top: 8px;
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .footer {
    &_contactDetails {
      display: flex;
      padding-left: 28px;
      align-items: center;
      margin: 16px auto 0px;
      justify-content: space-between;
      & > ul {
        display: block;
        margin: 0px -6px;
      }
    }
  }
}
