.loader {
  @include positionAbsoluteTopLeft;
  top: 50%;
  right: 0px;
  width: 120px;
  height: 120px;
  margin: -60px auto 0px;
  &_overlay {
    @include positionFixed;
    height: 100%;
    z-index: 200;
    background-color: rgba(0, 0, 0, 0.6);
    & > .fsmLoader {
      @include positionAbsoluteTopLeft;
      top: 50%;
      right: 0px;
      width: 80px;
      height: 80px;
      font-size: 48px;
      border-radius: 50%;
      margin: -40px auto 0px;
      background-color: $header-color;
      animation: flipItDude 1s ease-in-out infinite alternate-reverse both;
      -moz-animation: flipItDude 1s ease-in-out infinite alternate-reverse both;
      -webkit-animation: flipItDude 1s ease-in-out infinite alternate-reverse both;
      &.noFlip {
        animation: none;
        &::after {
          content: "";
          @include positionAbsoluteTopLeft;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          transform: translateZ(0);
          -moz-transform: translateZ(0);
          -webkit-transform: translateZ(0);
          border-top: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid transparent;
          animation: load8 1.1s infinite linear;
          -moz-animation: load8 1.1s infinite linear;
          border-left: 10px solid $header-color-dark;
          -webkit-animation: load8 1.1s infinite linear;
        }
      }
      &::before {
        content: "";
        @include positionAbsoluteTopLeft;
        top: 50%;
        right: 0px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: -30px auto 0px;
        background-color: $header-color-dark;
      }
      & > svg {
        @include positionAbsoluteTopLeft;
        top: 50%;
        right: 0px;
        width: 24px;
        height: 24px;
        fill: #fff;
        margin: 0px auto;
        @include transformTranslateY(-50%);
        text-shadow: 6px 6px 10px rgba(51, 51, 51, 1);
      }
    }
  }
}
@keyframes flipItDude {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(-180deg);
  }
}
@-webkit-keyframes flipItDude {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(-180deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
