.speakerForm {
  padding: 0 30px 0 46px;
  line-height: 24px;
  min-height: calc(100vh - 300px);
  background: url("../../../assets/img/SponsorFormGradient.svg") repeat-x;
  background-position-y: -120px;
  overflow-x: hidden;
  &--onIe {
    background: none !important;
    min-height: calc(100vh - 252px);
  }
  h2,
  h3 {
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    font-weight: normal;
    font-family: $font-title;
    padding: 6px 0;
    margin: 50px auto 30px;
    background-color: rgba(133, 214, 255, 0.2);
    width: calc(100% + 76px);
    margin-left: -46px;
  }
  fieldset {
    margin-bottom: 10px !important;
  }
  p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .hs_submit input {
    margin: 40px auto;
  }
  .hs_proposed_role {
    margin-bottom: 16px;
  }
  .hs_recaptcha {
    margin-top: 100px;
  }
  .hs-form-field > label {
    font-family: $font-bold;
    font-size: 16px;
    margin-bottom: 6px;
    display: block;
    > span > em {
      font-family: $font-semi;
      font-style: italic;
      margin-right: 2px;
    }
    + legend {
      margin-top: -6px;
      margin-bottom: 10px;
    }
  }
  &_topSection {
    padding-top: 60px;
    max-width: 1200px;
    margin: 0 auto;
    h1 {
      width: 100%;
      font-family: $font-title;
      font-size: 38px;
      line-height: 46px;
      text-align: center;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
    > p {
      margin-bottom: 30px;
      font-size: 18px;
    }
    > div {
      font-size: 18px;
      > label {
        margin-bottom: 20px;
      }
    }
    ul {
      > li {
        margin-bottom: 6px;
      }
    }
  }
  .submitted-message {
    overflow: visible !important;
    font-size: 24px;
    color: $success;
    line-height: 24px;
    text-align: center;
    margin: 30vh auto 40px;
    font-family: $font-bold;
    > p {
      font-size: 24px;
      margin: 0;
    }
  }
}

@media only screen and (max-width: $device-max-ipad) {
  .speakerForm {
    padding: 0 50px;
    &_topSection {
      padding-top: 30px;
      h1 {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 20px;
      }
      > p,
      > div {
        font-size: 17px;
      }
    }
    h2,
    h3 {
      font-size: 28px;
      line-height: 36px;
      margin: 24px auto 10px;
      width: calc(100% + 100px);
      margin-left: -50px;
    }
    .hs_recaptcha {
      margin-top: 50px;
    }
  }
}
@media only screen and (max-width: $device-max-mobile) {
  .speakerForm {
    padding: 0 15px;
    fieldset {
      margin-bottom: 0 !important;
    }
    h2,
    h3 {
      margin: 40px auto 14px;
      width: calc(100% + 30px);
      margin-left: -15px;
      padding: 10px 30px;
    }
    .submitted-message {
      margin: 70px auto 50px;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .speakerForm {
    h2,
    h3 {
      width: 100vw;
      margin-left: calc(((100vw - 1184px) * 0.5) * -1);
    }
  }
}
