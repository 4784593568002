.feedbackGradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(142, 208, 186, 0) 0%,
    rgba(142, 208, 186, 0) 1%,
    rgba(142, 208, 186, 0.4) 25%,
    rgba(133, 214, 255, 0.4) 50%,
    rgba(142, 208, 186, 0.4) 75%,
    rgba(142, 208, 186, 0) 100%
  );
}
.global_section {
  &_futureOfEvents {
    &_img {
      max-width: 100%;
      margin-bottom: 30px;
    }
  }
  &_feedback {
    &_heading {
      margin: 70px 0 20px;
      font-size: 62px;
      line-height: 72px;
      color: $black;
      font-family: $font-bold;
      > span {
        display: block;
        font-size: 86px;
        line-height: 96px;
      }
    }
    &_title {
      font-size: 54px;
      line-height: 64px;
      letter-spacing: 0.5px;
    }
    &_subtitle {
      font-size: 32px;
      line-height: 42px;
      color: $black;
      padding: 0 50px;
      font-family: $font-regular;
      margin: 0px auto 32px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-family: $font-semi;
      }
    }
  }
  &_showOfYear {
    .feedbackListing {
      li {
        margin: 0 30px;
        &:nth-child(1) {
          h6 {
            font-family: $font-semi;
            font-size: 38px;
            line-height: 48px;
          }
        }
        &:nth-child(2) {
          h6 {
            font-size: 18px;
            line-height: 24px;
            em {
              font-family: $font-bold;
            }
            span {
              font-family: $font-regular;
            }
          }
        }
        &:nth-child(3) {
          h4 {
            font-family: $font-bold;
            font-size: 100px;
            line-height: 110px;
          }
          h6 {
            font-family: $font-semi;
          }
        }
      }
    }
  }
}
.feedbackListing {
  @include displayFlexCenter;
  width: 100%;
  margin: 20px auto 50px;
  li {
    width: 20%;
    padding: 6px;
    .circleBg {
      background-color: $listingBg;
      color: $white;
      @include displayFlexCenter;
      flex-direction: column;
      padding: 15px;
      width: 248px;
      height: 248px;
      border-radius: 50%;
      text-align: center;
      h4 {
        font-size: 46px;
        line-height: 56px;
        font-family: $font-bold;
      }
      h6 {
        font-size: 26px;
        line-height: 36px;
        font-family: $font-bold;
      }
    }
  }
  &.thumbnailSection {
    margin-bottom: 0;
    li {
      width: 25%;
      padding: 0 16px;
      .thumbnailSection_wrapper {
        text-align: center;
        img {
          width: 100%;
          max-width: 260px;
          margin: 0 auto;
        }
        p {
          margin: 30px 0 0;
          font-size: 24px;
          line-height: 30px;
          font-family: $font-bold;
        }
      }
    }
  }
}
.imgOrVideoSection {
  .media_wrapper {
    margin-bottom: 50px;
    img,
    video {
      width: 100%;
    }
  }
  .cloud_section {
    background-repeat: no-repeat;
    margin: 0 auto;
    background-size: contain;
    text-align: center;
    h4 {
      font-size: 24px;
      line-height: 32px;
      color: $black;
      font-family: $font-semi;

      span {
        display: block;
        font-family: $font-regular;
      }
    }
  }
  &.bigCloud {
    .cloud_section {
      background-image: url($bucket_url + "/assets/img/cloud_svg.svg");
      width: 996px;
      height: 245px;
      padding: 35px 50px 70px;
    }
  }
  &.smallCloud {
    .cloud_section {
      background-image: url($bucket_url + "/assets/img/smallCloud_svg.svg");
      width: 996px;
      height: 180px;
      padding: 35px 50px 50px;
    }
  }
  &.flipCloud {
    .cloud_section {
      transform: scaleX(-1);
      h4 {
        transform: scaleX(-1);
      }
    }
  }
  .global_section_subtitle {
    margin-top: 50px;
  }
}

@media (max-width: $device-small-desktop) {
  .global_section {
    &_feedback {
      &_heading {
        font-size: 58px;
        line-height: 68px;
        span {
          font-size: 74px;
          line-height: 84px;
        }
      }
      &_title {
        font-size: 48px;
        line-height: 58px;
      }
      &_subtitle {
        font-size: 26px;
        line-height: 36px;
      }
    }
    &_showOfYear {
      .feedbackListing {
        li {
          &:nth-child(1) {
            .circleBg {
              h4,
              h6 {
                font-size: 32px;
                line-height: 42px;
              }
            }
          }
          &:nth-child(2) {
            .circleBg {
              h6 {
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
          &:nth-child(3) {
            .circleBg {
              h4 {
                font-size: 80px;
                line-height: 90px;
              }
              h6 {
                font-size: 24px;
                line-height: 34px;
              }
            }
          }
        }
      }
    }
  }
  .feedbackListing {
    li {
      .circleBg {
        width: 220px;
        height: 220px;
        h4 {
          font-size: 38px;
          line-height: 48px;
        }
        h6 {
          font-size: 22px;
          line-height: 32px;
        }
      }
    }
    &.thumbnailSection {
      li {
        .thumbnailSection_wrapper {
          img {
            max-width: 220px;
          }
          p {
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .global_section {
    &_feedback {
      &_heading {
        font-size: 48px;
        line-height: 58px;
        span {
          font-size: 64px;
          line-height: 74px;
        }
      }
      &_title {
        font-size: 42px;
        line-height: 52px;
      }
      &_subtitle {
        font-size: 22px;
        line-height: 32px;
      }
    }
    &_showOfYear {
      .feedbackListing {
        li {
          &:nth-child(1) {
            .circleBg {
              h4,
              h6 {
                font-size: 26px;
                line-height: 36px;
              }
            }
          }
          &:nth-child(2) {
            .circleBg {
              h4 {
                font-size: 24px;
                line-height: 34px;
              }
              h6 {
                font-size: 13px;
                line-height: 20px;
              }
            }
          }
          &:nth-child(3) {
            .circleBg {
              h4 {
                font-size: 64px;
                line-height: 74px;
              }
              h6 {
                font-size: 18px;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  }
  .imgOrVideoSection {
    &.smallCloud,
    &.bigCloud {
      .cloud_section {
        width: 860px;
        padding: 25px 40px 50px;
      }
    }
    &.smallCloud {
      .cloud_section {
        height: 155px;
      }
    }
    &.bigCloud {
      .cloud_section {
        height: 214px;
      }
    }
  }
  .feedbackListing {
    li {
      .circleBg {
        width: 180px;
        height: 180px;
        h4 {
          font-size: 28px;
          line-height: 38px;
        }
        h6 {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
    &.thumbnailSection li .thumbnailSection_wrapper {
      img {
        max-width: 180px;
      }
      P {
        font-size: 17px;
        line-height: 22px;
      }
    }
  }
}
@media (max-width: $device-max-ipad-potrait) {
  .global_section {
    &_feedback {
      &_heading {
        font-size: 36px;
        line-height: 46px;
        span {
          font-size: 48px;
          line-height: 58px;
        }
      }
      &_title {
        font-size: 34px;
        line-height: 42px;
      }
      &_subtitle {
        font-size: 18px;
        line-height: 28px;
      }
    }
    &_showOfYear .feedbackListing li .circleBg {
      width: 180px;
      height: 180px;
    }
  }
  .imgOrVideoSection {
    &.smallCloud,
    &.bigCloud {
      .cloud_section {
        width: 680px;
        padding: 15px 25px 40px;
        h4 {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
    &.smallCloud {
      .cloud_section {
        height: 125px;
      }
    }
    &.bigCloud {
      .cloud_section {
        height: 170px;
      }
    }
  }
  .feedbackListing {
    li {
      .circleBg {
        width: 140px;
        height: 140px;
        h4 {
          font-size: 24px;
          line-height: 34px;
        }
        h6 {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

@media (max-width: $device-max-mobile) {
  .global_section {
    &_feedback {
      &_heading {
        font-size: 20px;
        line-height: 30px;
        margin: 32px 0 0;
        span {
          font-size: 38px;
          line-height: 42px;
        }
      }
      &_title {
        font-size: 26px;
        line-height: 36px;
      }
      &_subtitle {
        font-size: 16px;
        line-height: 26px;
        padding: 0;
      }
    }
  }
  .feedbackListing {
    flex-wrap: wrap;
    li {
      margin: 0 !important;
      width: 100% !important;
      .circleBg {
        margin: 0 auto;
      }
    }
    &.thumbnailSection {
      li {
        padding: 0 16px 40px;
        .thumbnailSection_wrapper {
          p {
            margin: 15px 0 0;
          }
          img {
            max-width: 140px;
          }
        }
      }
    }
  }
  .imgOrVideoSection {
    .media_wrapper {
      margin-bottom: 30px;
    }
    .global_section_subtitle {
      margin-top: 30px;
    }
    &.bigCloud,
    &.smallCloud {
      .cloud_section {
        background-image: url($bucket_url + "/assets/img/mobile_cloud_svg.svg") !important;
        height: 115px;
        width: 300px;
        padding: 10px 10px 40px;
        @include displayFlex;
        h4 {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}
