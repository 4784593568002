.global {
  &_section {
    &_schedule {
      padding: 64px 0px 0px;
      &_content {
        position: relative;
        margin-bottom: 32px;
        @include displayFlex;
        &_left {
          flex: 55%;
          max-width: 55%;
          & > .global_section_content {
            & > ul {
              padding-left: 12px;
              list-style-type: none;
              & > li {
                position: relative;
                font-size: 0px;
                & > svg,
                & > span {
                  position: relative;
                  font-size: 16px;
                  @include inlineViewTop;
                }
                & > svg {
                  top: 4px;
                  width: 16px;
                  height: 16px;
                }
                & > span {
                  margin-left: 8px;
                  width: calc(100% - 24px);
                  width: -moz-calc(100% - 24px);
                  width: -webkit-calc(100% - 24px);
                }
              }
            }
          }
        }
        &_right {
          flex: 45%;
          max-width: 45%;
          margin-left: 36px;
          & > img {
            max-width: 70%;
          }
        }
      }
      &_img {
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: $device-max-mobile) {
  .global {
    &_section {
      &_schedule {
        padding: 32px 0px 0px;
        &_content {
          display: block;
          &_left,
          &_right {
            max-width: none;
          }
          &_right {
            margin: 24px 0px 0px;
            & > img {
              display: block;
              max-width: 70%;
              margin: 0px auto;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) {
  .global {
    &_section {
      &_schedule {
        padding: 32px 0px 0px;
        &_content {
          &_right {
            & > img {
              max-width: 85%;
            }
          }
        }
      }
    }
  }
}
