@mixin boxSizing {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
@mixin anchor_text {
  color: $anchor-color;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
}
@mixin list {
  padding: 0px;
  font-size: 0px;
  margin: 0px -6px;
  list-style-type: none;
}
@mixin list_inline {
  font-size: $font-std;
  padding: 0px 6px;
  display: inline-block;
  vertical-align: middle;
}
@mixin listUnstyled {
  padding: 0px;
  margin: 0px auto;
  list-style-type: none;
}
@mixin disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}
@mixin boxShadow($values) {
  box-shadow: $values;
  -moz-box-shadow: $values;
  -webkit-box-shadow: $values;
}
@mixin transition($values) {
  transition: $values;
  -moz-transition: $values;
  -webkit-transition: $values;
}
@mixin inlineViewMiddle {
  display: inline-block;
  vertical-align: middle;
}
@mixin inlineViewTop {
  vertical-align: top;
  display: inline-block;
}
@mixin positionAbsoluteTop {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 4;
}
@mixin positionAbsoluteBottom {
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 4;
  bottom: 0px;
}
@mixin positionAbsoluteTopLeft {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
}
@mixin positionAbsoluteTopRight {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 4;
}
@mixin positionAbsoluteBottomRight {
  position: absolute;
  right: 0px;
  z-index: 4;
  bottom: 0px;
}
@mixin positionAbsoluteBottomLeft {
  position: absolute;
  left: 0px;
  z-index: 4;
  bottom: 0px;
}
@mixin positionFixed {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 4;
}
@mixin positionFixedBottom {
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 4;
  bottom: 0px;
}
@mixin positionFixedTopLeft {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 4;
}
@mixin positionFixedTopRight {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 4;
}
@mixin positionFixedBottomLeft {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 4;
}
@mixin positionFixedBottomRight {
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 4;
}
@mixin positionStickyTopLeft {
  position: sticky;
  top: 0px;
  left: 0px;
}
@mixin textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin transformRotate($values) {
  transform: rotate($values);
  -moz-transform: rotate($values);
  -webkit-transform: rotate($values);
}
@mixin transformScale($values) {
  transform: scale($values);
  -moz-transform: scale($values);
  -webkit-transform: scale($values);
}
@mixin transformTranslate($values) {
  transform: translateX($values) translateY($values);
  -moz-transform: translateX($values) translateY($values);
  -webkit-transform: translateX($values) translateY($values);
}
@mixin transformTranslateX($values) {
  transform: translateX($values);
  -moz-transform: translateX($values);
  -webkit-transform: translateX($values);
}
@mixin transformTranslateY($values) {
  transform: translateY($values);
  -moz-transform: translateY($values);
  -webkit-transform: translateY($values);
}
@mixin transformRotateX($values) {
  transform: rotateX($values);
  -moz-transform: rotateX($values);
  -webkit-transform: rotateX($values);
}
@mixin flex {
  display: flex;
}

@mixin displayFlex {
  display: flex;
  align-items: center;
}
@mixin displayFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin displayFlexEnd {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

@mixin flexHCenter {
  display: flex;
  justify-content: center;
}
