.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.container {
  position: relative;
  margin: 0px auto;
  padding: 0px 12px;
}
.container-fluid {
  position: relative;
  padding: 0px 12px;
}
.list-inline {
  @include list;
  > li {
    @include list_inline;
  }
}
.list-unstyled {
  @include listUnstyled;
}
.disabled {
  @include disabled;
}
.smooth {
  @include transition(0.5s);
}
.lgVisible {
  display: inline-block !important;
}
.xsVisible {
  display: none !important;
}
.hasTextDecoration:hover {
  text-decoration: underline;
}
.hide {
  display: none !important;
}
.hasErrorColor {
  color: $error;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  align-items: center;
  justify-content: space-between;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0px 12px;
}
.col-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 0px 12px;
}
@media only screen and (max-width: $device-max-mobile) {
  .lgVisible {
    display: none !important;
  }
  .xsVisible {
    display: inline-block !important;
  }
  .container {
    width: 100%;
    padding: 0px 12px;
  }
}
@media only screen and (max-width: $device-max-ipad-potrait) {
  .col-4,
  .col-6 {
    width: 100%;
    flex: initial;
    max-width: initial;
  }
}
@media only screen and (min-width: $device-desktop) {
  .container {
    max-width: 1320px;
  }
  .container-fluid {
    padding: 0px 50px;
  }
}
