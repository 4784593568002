.registrationClosed {
  background-image: url($bucket_url + "/assets/img/register-gradient.png");
  background-repeat: repeat-y;
  background-position: right;
  height: 100%;
  .contentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;
  }
  .image {
    width: 40%;
  }
  .content {
    min-width: 50%;
    max-width: 55%;
    margin-left: 20px;
    > h1 {
      font-family: $font-title;
      font-size: 34px;
      line-height: 41px;
      margin-bottom: 34px;
    }
    > p {
      font-family: $font-title;
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 70px;
    }
    > div {
      font-family: $font-brandon-regular;
      font-size: 19px;
      line-height: 22px;
      font-weight: normal;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      a {
        color: #263271;
        display: inline;
        vertical-align: unset;
        &:hover {
          text-decoration: underline;
        }
      }
      small {
        background: url($bucket_url + "/assets/img/mail_icon.png") no-repeat center;
        height: 70px;
        min-width: 90px;
        background-size: contain;
        margin-right: 8px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .registrationClosed .content {
    > h1 {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 18px;
    }
    > p {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    > div {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 50px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .registrationClosed {
    .contentWrapper {
      flex-wrap: wrap;
      padding: 30px 15px;
    }
    .content {
      width: 100%;
      max-width: unset;
      margin: 0;
      & > p {
        & > span {
          display: inline-block;
        }
      }
      > div small {
        height: 50px;
        min-width: 50px;
      }
    }
    .image {
      width: 300px;
      margin: 0 auto;
    }
  }
}
