.hs-form {
  max-width: 1200px;
  margin: 0 auto;
}
.form-columns-2,
.form-columns-1,
.form-columns-0 {
  width: 100%;
  max-width: none !important;
}
.form-columns-3 {
  width: 100%;
  max-width: none !important;
  .field {
    width: 33.33% !important;
  }
}
.hs-form-required {
  color: $asterisk;
  margin-left: 2px;
}
.hs-fieldtype-text {
  text-align: left;
  margin-bottom: 14px !important;
  .input {
    max-height: 98px;
  }
  .hs-form-required {
    color: $asterisk;
    margin-left: 2px;
  }
  input,
  select,
  textarea {
    position: relative;
    width: 100% !important;
    color: $text-color;
    appearance: none;
    padding: 10px 12px;
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid $formElementBorder;
    background-color: $formElementBg;
    border-radius: 10px;
    font-size: 16px;
  }
  textarea {
    resize: none;
    height: 98px;
  }
  .input {
    margin-right: 16px !important;
  }
  > label {
    display: inline-block;
    font-family: $font-bold;
    font-size: 16px;
    margin-bottom: 8px;
  }
}
::-webkit-input-placeholder {
  /* Edge */
  color: $text-color;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $text-color;
}
::placeholder {
  color: $text-color;
}
.hs-fieldtype-number,
.hs-fieldtype-select,
.hs-fieldtype-textarea,
.hs-fieldtype-phonenumber {
  @extend .hs-fieldtype-text;
}
.hs-submit {
  padding-top: 20px !important;
  input {
    position: relative;
    height: 50px;
    cursor: pointer;
    margin: 0px auto;
    line-height: 20px;
    padding: 0px 24px;
    text-transform: uppercase;
    font-family: "brandon_grotesque", "Open Sans", "Helvetica", "Arial", sans-serif;
    width: 500px;
    max-width: calc(100% - 30px);
    border: 1px solid $formElementBorder;
    background-color: $formElementBg;
    color: $text-color;
    font-size: 22px;
    border-radius: 40px;
    &:hover {
      background-color: $header-color;
      color: #fff;
      @include transition(0.5s);
    }
  }
  .actions {
    text-align: center;
  }
}
.hs_error_rollup {
  margin-top: 10px;
  label {
    text-align: center;
  }
}
.hs-error-msgs {
  list-style: none;
  margin: 4px 0 0;
  padding: 0;
  text-align: left;
  label {
    position: relative;
    display: block;
    font-size: 13px;
    color: $error;
  }
}
.inputs-list {
  list-style: none;
  padding: 0 0 0 0;
  margin: 0;
  li {
    font-size: 16px;
    & + li {
      margin-top: 4px;
    }
  }
}
.grecaptcha-badge {
  margin: 0 auto;
}
.hs-form-checkbox,
.hs-form-booleancheckbox {
  > label {
    position: relative;
    > input {
      @include positionAbsoluteTopLeft;
      opacity: 0;
      height: 100%;
      cursor: pointer;
      margin: 0px auto;
      width: 100% !important;
      &:checked {
        + span {
          &:after {
            opacity: 1;
            visibility: visible;
          }
          &::before {
            background-color: $formElementBorder;
          }
        }
      }
    }
    > span {
      position: relative;
      cursor: pointer;
      padding-left: 30px;
      @include inlineViewTop;
      &:before {
        content: "";
        @include positionAbsoluteTopLeft;
        top: 2px;
        width: 20px;
        height: 20px;
        border: 1px solid $formElementBorder;
        background-color: $formElementBg;
        border-radius: 5px;
      }
      &:after {
        content: "";
        position: absolute;
        top: 14px;
        left: 7px;
        width: 6px;
        opacity: 0;
        z-index: 4;
        height: 12px;
        visibility: hidden;
        margin: -9px auto 0px;
        transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotate(45deg);
      }
    }
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.hs-form-booleancheckbox {
  margin: 20px 0 40px 0;
  input + span {
    font-family: $font-bold;
    > em {
      font-family: $font-semi;
      font-style: italic;
    }
  }
}
.hs-form-radio {
  > label {
    position: relative;
    > input {
      @include positionAbsoluteTopLeft;
      opacity: 0;
      height: 100%;
      cursor: pointer;
      margin: 0px auto;
      width: 100% !important;
      &:checked {
        + span {
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    > span {
      position: relative;
      cursor: pointer;
      padding-left: 22px;
      @include inlineViewTop;
      line-height: 18px;
      &::before {
        content: "";
        @include positionAbsoluteTopLeft;
        top: 2px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid $formElementBorder;
        background-color: $formElementBg;
      }
      &::after {
        content: "";
        position: absolute;
        top: 9px;
        left: 3px;
        opacity: 0;
        width: 8px;
        height: 8px;
        visibility: hidden;
        border-radius: 50%;
        z-index: 10;
        @include transformTranslateY(-50%);
        background-color: $formElementBorder;
      }
    }
  }
}

.hs-fieldtype-select {
  .input {
    position: relative;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 9px;
      right: 8px;
      width: 0px;
      z-index: 2;
      height: 0px;
      border-style: solid;
      pointer-events: none;
      border-width: 0px 6px 6px 6px;
      border-color: transparent transparent $formElementBorder transparent;
    }
    &:after {
      top: auto;
      bottom: 11px;
      border-width: 6px 6px 0px 6px;
      border-color: $formElementBorder transparent transparent transparent;
    }
  }
}
@media only screen and (max-width: 767px) {
  .hs-fieldtype-text {
    width: 100% !important;
  }
  .inputs-list {
    padding-left: 10px;
    li {
      font-size: 16px;
      & + li {
        margin-top: 8px;
      }
    }
  }
  .hs-form-booleancheckbox {
    margin-left: -10px;
  }
  .form-columns-2,
  .form-columns-1,
  .form-columns-0,
  .form-columns-3 {
    .input {
      margin-right: 0 !important;
    }
    .field {
      width: 100% !important;
    }
  }
}
