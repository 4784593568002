.header {
  .isMobilePrivacy & {
    display: none;
  }
  @include positionFixed;
  top: 0;
  z-index: 98;
  border-bottom: 2px solid #fff;
  background-color: $header-color;
  &_container {
    position: relative;
    font-size: 0px;
    & > b {
      @include positionAbsoluteTopLeft;
      top: 10px;
      left: 50%;
      color: #fff;
      font-size: 18px;
      margin: 0px auto;
      text-align: center;
      line-height: $lineheight-font-std;
      @include transformTranslateX(-50%);
    }
  }
  &_logo,
  &_navLinks {
    position: relative;
    width: 360px;
    font-size: $font-std;
    @include inlineViewTop;
  }
  &_logo {
    height: 90px;
    text-align: center;
    &::after {
      content: "";
      @include positionAbsoluteBottomLeft;
      width: 360px;
      height: 50px;
      bottom: -26px;
      background-size: 100%;
      background-position: bottom;
      background-repeat: no-repeat;
      background-image: url($bucket_url + "/assets/img/headerBg_new.png");
    }
    & > a {
      @include positionAbsoluteTopLeft;
      top: 58%;
      left: 50%;
      z-index: 6;
      transform: translateX(-50%) translateY(-50%);
      & > svg {
        width: 300px;
        height: 60px;
        display: block;
        .cls-1 {
          font-family: "gothammedium", sans-serif;
        }
      }
    }
  }
  &_navLinks {
    text-align: right;
    width: calc(100% - 360px);
    width: -moz-calc(100% - 360px);
    width: -webkit-calc(100% - 360px);
    &_top {
      position: relative;
      margin: 14px auto 0px;
    }
    &_bottom {
      position: relative;
      &_nav {
        position: relative;
        font-size: 0px;
        &.timerIsElapsed {
          & > ul,
          & > .countDown_timer {
            @include inlineViewMiddle;
          }
          & > ul {
            margin: 0px -8px;
          }
        }
        & > ul,
        & > .countDown_timer {
          @include inlineViewTop;
        }
        & > ul {
          margin: 0px auto;
          & > li {
            position: relative;
            padding: 0px;
            &.hasSubMenu {
              perspective: 1000px;
              -moz-perspective: 1000px;
              -webkit-perspective: 1000px;
              & > span {
                position: relative;
                padding-right: 24px;
                &:hover {
                  text-decoration: none;
                }
                &::after {
                  content: "";
                  @include positionAbsoluteTopRight;
                  top: 50%;
                  right: 4px;
                  width: 0px;
                  height: 0px;
                  border-style: solid;
                  margin: -2px auto 0px;
                  @include transition(0.5s);
                  border-width: 5px 5px 0px 5px;
                  border-color: #fff transparent transparent transparent;
                }
              }
              &:hover {
                & > span {
                  &::after {
                    @include transformRotate(180deg);
                  }
                }
                & > .header_navLinks_subMenu {
                  display: block;
                }
              }
            }
            & > a,
            & > span {
              position: relative;
              color: #fff;
              display: block;
              cursor: pointer;
              padding: 20px 8px;
              font-size: $font-md;
              text-transform: uppercase;
              line-height: $lineheight-font-sm;
              &:hover {
                text-decoration: underline;
              }
            }
            & > a.get_ticket_btn{
              position: relative;
              display: block;
              cursor: pointer;
              margin-left: 1em;
              //font-size: 22px;
              font-family: $font-bold;
              padding: 0.4em 1em 0.3em 1em;
              // line-height: 24px;
              color: #263271;
              border-radius: 8px;
              //font-family: "brandon_grotesque", sans-serif;
              border: 2px solid #263271;
              background-color: #faf4a8;
            }
            
            & > a.get_ticket_btn:hover{
              //zoom: 0.95;
              background-color: #ef7a2b;
              text-decoration: none;
              transform: scale(1.05);
              border: 2px solid #000;
              color: #fff;
            }
            // &:last-child {
            //   & > a {
            //     color: #faf4a8;
            //     font-family: $font-bold;
            //   }
            // }
          }
        }
        & > .countDown_timer {
          margin-left: 32px;
        }
      }
    }
    &_subMenu {
      @include positionAbsoluteTopLeft;
      top: 60px;
      display: none;
      text-align: left;
      perspective: 1000px;
      background-color: #114419;
      transform-origin: top center;
      animation: showSubMenu 400ms ease-in-out forwards;
      -moz-animation: showSubMenu 400ms ease-in-out forwards;
      -webkit-animation: showSubMenu 400ms ease-in-out forwards;
      & > li {
        & > span,
        & > a {
          color: #fff;
          display: block;
          font-size: $font-md;
          text-transform: uppercase;
          padding: 10px 36px 10px 12px;
          line-height: $lineheight-font-sm;
          &:hover {
            text-decoration: underline;
            background-color: #03380b;
            cursor: pointer;
          }
        }
      }
    }
  }
  &_infoBanner {
    @include positionFixed;
    z-index: 96;
    font-size: $font-md;
    color: $white;
    line-height: 32px;
    padding: 8px 0;
    text-transform: uppercase;
    background-color: $violetColor;
    height: 48px;
    .overlay {
      display: none;
    }
    .marquee {
      span {
        margin: 0 25px;
        font-family: $font-bold;
        abbr {
          margin-left: 50px;
          display: inline-block;
        }
      }
    }
  }
  &_top {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 15px 0;
    background-color: $yellow;
    color: $black;
    text-transform: uppercase;
    font-family: $font-bold;
    height: 50px;
    .overlay {
      display: none;
    }
    .marquee {
      font-size: 18px;
      padding: 0 15px;
      & p > span {
        color: $red;
      }
    }
  }
}
.socialMedia {
  & > li {
    & > a {
      color: #fff;
      display: block;
      font-size: $font-md;
      & > svg {
        width: 16px;
        height: 16px;
        display: block;
      }
    }
    & > span {
      color: #fff;
      display: block;
      font-size: 0px;
      cursor: pointer;
      > span {
        border-bottom: 1px solid transparent;
      }
      & > svg,
      & > span {
        font-size: $font-std;
        @include inlineViewMiddle;
      }
      & > svg {
        width: 20px;
        height: 16px;
        margin-right: 8px;
      }
      &:hover {
        text-decoration: underline;
        > span {
          border-color: #fff;
        }
      }
    }
    &.joinMailList {
      position: relative;
      margin-left: 10px;
      padding-left: 16px;
      &::before {
        content: "";
        @include positionAbsoluteTopLeft;
        top: 50%;
        width: 1px;
        height: 120%;
        background-color: #fff;
        @include transformTranslateY(-50%);
      }
    }
  }
}



@media only screen and (max-width: 1366px) {
  .header_navLinks_bottom_nav > ul > li > a,
  .header_navLinks_bottom_nav > ul > li > span {
    font-size: 15px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .header {
    &_logo {
      width: 240px;
      height: 70px;
      &::after {
        width: 240px;
        bottom: -38px;
      }
      > a {
        top: 80%;
      }
    }
    &_logo > a > svg {
      width: 200px;
    }
    &_navLinks {
      width: calc(100% - 240px);
      &_bottom_nav {
        > ul > li > a,
        > ul > li > span {
          font-size: 13px !important;
          padding: 20px 5px;
        }
      }
    }
  }
}
@media only screen and (max-width: $device-max-mobile) {
  .header {
    &_logo,
    &_navLinks {
      @include inlineViewMiddle;
    }
    &_logo {
      height: auto;
      text-align: left;
      width: calc(100% - 38px);
      width: -moz-calc(100% - 38px);
      width: -webkit-calc(100% - 38px);
      &::after {
        content: none;
      }
      & > a {
        position: relative;
        left: 0px;
        bottom: 0px;
        @include transformTranslateX(0px);
        & > svg {
          width: 220px;
          height: auto;
        }
      }
    }
    &_navLinks {
      width: 38px;
      &_bottom {
        @include positionFixedTopRight;
        z-index: 8;
        width: 100%;
        right: -100%;
        overflow: hidden;
        overflow-y: auto;
        @include transition(0.5s);
        background-color: $header-color;
        border-top: 2px solid $border-color;
        &.openNav {
          right: 0px;
        }
        &_nav {
          height: 100%;
          &.text-right {
            text-align: center !important;
          }
          & > p {
            margin: 16px;
            color: #fff;
            font-size: $font-md;
            & > span {
              display: block;
            }
          }
          & > ul,
          & > .countDown_timer {
            display: block;
          }
          & > .countDown_timer {
            margin: 12px auto 0px;
          }
          & > ul {
            margin: 0px auto;
            & > li {
              &.hasSubMenu {
                & > span {
                  padding: 14px 24px;
                  display: inline-block;
                  vertical-align: middle;
                }
                &:hover {
                  & > span {
                    &::after {
                      @include transformRotate(0deg);
                    }
                  }
                }
              }
              padding: 0px;
              display: block;
              & > span,
              & > a {
                padding: 14px 0px;
              }
            }
          }
        }
      }
      &_subMenu {
        position: relative;
        top: 0px;
        display: none !important;
        animation: none;
        &.subMenuOpen {
          display: block !important;
        }
        & > li {
          & > span,
          & > a {
            padding: 14px 0px;
            text-align: center;
            background-color: #03380b;
            &:hover {
              text-decoration: none;
            }
          }
          & + li {
            border-top: 1px solid #4b5f4e;
          }
        }
      }
    }
    &_container {
      padding: 12px 0px;
    }
    &_infoBanner {
      .marquee {
        span {
          font-size: $font-std;
        }
      }
    }
  }
  .showMenu {
    @include positionAbsoluteTopRight;
    top: 50%;
    width: 38px;
    height: 24px;
    cursor: pointer;
    @include transformTranslateY(-50%);
    & > small {
      @include positionAbsoluteTopRight;
      z-index: 0;
      width: 100%;
      height: 4px;
      pointer-events: none;
      background-color: #fff;
      &.b {
        top: 10px;
      }
      &.c {
        top: 20px;
      }
    }
    &.menuOpened {
      & > small {
        &.b {
          width: 80%;
        }
        &.c {
          width: 60%;
        }
      }
    }
  }
}
@media only screen and (min-width: $device-min-ipad) and (max-width: $device-max-ipad) {
  .header {
    &_logo,
    &_navLinks {
      width: 100%;
      display: block;
    }
    &_logo {
      height: auto;
      &::after {
        content: none;
      }
      & > a {
        position: relative;
        left: 0px;
        bottom: 0px;
        @include transformTranslateX(0px);
        & > svg {
          width: 180px;
        }
      }
    }
    &_navLinks {
      text-align: center;
      &_top {
        margin: 0px auto 6px;
        display: block !important;
      }
      &_bottom {
        margin: 0px auto;
        &_nav {
          text-align: center !important;
          > ul > li > a,
          > ul > li > span {
            font-size: 13px;
            padding: 20px 5px;
          }
          & > .countDown_timer {
            margin-left: 20px;
          }
        }
      }
      &_subMenu {
        top: 66px;
      }
    }
    &_container {
      padding: 6px 0px;
      &::after {
        content: none;
      }
      & > b {
        font-size: $font-md;
        line-height: $lineheight-font-sm;
      }
    }
    &_infoBanner {
      .marquee {
        span {
          font-size: $font-std;
        }
      }
    }
  }
}

@media only screen and (min-width: $device-desktop) and (max-width: 1136px) {
  .header {
    &_navLinks {
      &_bottom {
        &_nav {
          & > ul {
            & > li {
              & > a,
              & > span {
                font-size: 14px;
              }
            }
          }
          .countDown_timer {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 380px) and (max-width: 647px) {
}
@keyframes showSubMenu {
  0% {
    @include transformRotateX(-90deg);
  }
  70% {
    @include transformRotateX(20deg);
  }
  100% {
    @include transformRotateX(0deg);
  }
}
@-moz-keyframes showSubMenu {
  0% {
    @include transformRotateX(-90deg);
  }
  70% {
    @include transformRotateX(20deg);
  }
  100% {
    @include transformRotateX(0deg);
  }
}
@-webkit-keyframes showSubMenu {
  0% {
    @include transformRotateX(-90deg);
  }
  70% {
    @include transformRotateX(20deg);
  }
  100% {
    @include transformRotateX(0deg);
  }
}
