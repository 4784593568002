.cookies {
  &_pageTitle {
    position: relative;
    font-size: 32px;
    line-height: 40px;
    color: $text-color;
    font-weight: normal;
    margin: 48px auto 24px;
  }
  &_page {
    &_contentHolder {
      position: relative;
      font-size: $font-md;
      padding-bottom: 32px;
      & > p {
        line-height: 24px;
        & + p {
          margin-top: 24px;
        }
        & > b {
          display: inline;
          vertical-align: baseline;
        }
        & > a {
          display: inline;
          vertical-align: baseline;
          text-decoration: underline;
        }
      }
    }
  }
}
@media only screen and (max-width: $device-max-mobile) {
  .cookies {
    &_pageTitle {
      font-size: 24px;
      line-height: 32px;
      margin: 24px auto;
      & > br {
        display: none;
      }
    }
    &_page {
      &_contentHolder {
        font-size: $font-std;
      }
    }
  }
}
