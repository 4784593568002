.waitListFormModal {
  @include positionFixed;
  width: 100vw;
  z-index: 1000;
  height: 100vh;
  display: flex;
  font-size: 16px;
  color: $text-color;
  align-items: center;
  justify-content: center;
  font-family: $font-semi;
  background-color: $modalBackdrop;
  &_dailog {
    position: relative;
    border: none;
    padding: 18px 24px;
    border-radius: 36px;
    background-color: transparent;
    &.formLoaded {
      width: 768px;
      background-color: #fff;
      border: 2px solid $border-color;
    }
    .submitted-message {
      overflow: visible !important;
      padding-right: 35px;
      & > p {
        font-size: 20px;
        color: $success;
        line-height: 28px;
        text-align: center;
        font-family: $font-bold;
        & > a {
          vertical-align: baseline;
        }
      }
    }
  }
  &_close {
    position: absolute;
    top: 16px;
    z-index: 4;
    right: 24px;
    width: 24px;
    height: 24px;
    padding: 0px;
    border: none;
    display: none;
    cursor: pointer;
    line-height: 26px;
    background-color: #f4f4f4;
    & > svg {
      width: 16px;
      height: 16px;
      display: block;
      margin: 0px auto;
    }
    .formLoaded & {
      display: block;
    }
  }
  h1 {
    display: block;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    font-weight: normal;
    margin: 0px auto 26px;
    font-family: "brandon_grotesque", sans-serif;
  }
  .hs-fieldtype-number,
  .hs-fieldtype-select,
  .hs-fieldtype-textarea {
    @extend .hs-fieldtype-text;
  }
  .hs-submit {
    input {
      font-size: 18px;
      max-width: 260px;
    }
  }
}
@media only screen and (max-width: $device-max-mobile) {
  .waitListFormModal {
    height: 100%;
    display: block;
    overflow: hidden;
    &_dailog {
      @include positionFixed;
      top: 50%;
      padding: 24px;
      overflow: hidden;
      overflow-y: auto;
      margin: 0px auto;
      border-radius: 12px;
      width: calc(100% - 16px);
      max-height: calc(100% - 16px);
      width: -moz-calc(100% - 16px);
      width: -webkit-calc(100% - 16px);
      max-height: -moz-calc(100% - 16px);
      @include transformTranslateY(-50%);
      max-height: -webkit-calc(100% - 16px);
      &.formLoaded {
        width: calc(100% - 16px);
        width: -moz-calc(100% - 16px);
        width: -webkit-calc(100% - 16px);
      }
      h1 {
        font-size: 26px;
        line-height: 36px;
        margin: 0px auto 16px;
      }
      form {
        width: 100%;
      }
      .submitted-message {
        & > p {
          font-size: 18px;
          text-align: center;
        }
      }
    }
    &_close {
      top: 12px;
      right: 16px;
    }
  }
}
