
.global_section {
  
    &_heading {
      margin: 70px 0 20px;
      font-size: 62px;
      line-height: 72px;
      color: $black;
      font-family: $font-bold;
      > span {
        display: block;
        font-size: 86px;
        line-height: 96px;
      }
    }
    &_title {
      font-size: 54px;
      line-height: 64px;
      letter-spacing: 0.5px;
    }
    &_subtitle p {
      font-size: 32px;
      line-height: 42px;
      color: $black;
      padding: 0 50px;
      font-family: $font-regular;
      margin: 0px auto 32px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-family: $font-semi;
      }
    }
  
  
}


@media (max-width: $device-small-desktop) {
  .global_section {
    
      &_heading {
        font-size: 58px;
        line-height: 68px;
        span {
          font-size: 74px;
          line-height: 84px;
        }
      }
      &_title {
        font-size: 48px;
        line-height: 58px;
      }
      &_subtitle {
        font-size: 26px;
        line-height: 36px;
      }

  }

}
@media (max-width: 1199px) {
  .global_section {
    
      &_heading {
        font-size: 48px;
        line-height: 58px;
        span {
          font-size: 64px;
          line-height: 74px;
        }
      }
      &_title {
        font-size: 42px;
        line-height: 52px;
      }
      &_subtitle {
        font-size: 22px;
        line-height: 32px;
      }
    

  }


}
@media (max-width: $device-max-ipad-potrait) {
  .global_section {
   
      &_heading {
        font-size: 36px;
        line-height: 46px;
        span {
          font-size: 48px;
          line-height: 58px;
        }
      }
      &_title {
        font-size: 34px;
        line-height: 42px;
      }
      &_subtitle {
        font-size: 18px;
        line-height: 28px;
      }

  }


}

@media (max-width: $device-max-mobile) {
  .global_section {
    
      &_heading {
        font-size: 20px;
        line-height: 30px;
        margin: 32px 0 0;
        span {
          font-size: 38px;
          line-height: 42px;
        }
      }
      &_title {
        font-size: 26px;
        line-height: 36px;
      }
      &_subtitle {
        font-size: 16px;
        line-height: 26px;
        padding: 0;
      }
    
  }


}

h4{
  font-size: 25px;
}
.resource-section {
  margin-bottom: 5em;
  position: relative;
  text-align: center;
  align-self: baseline !important;
}

.resource-section-icon {
  margin-bottom: .5em;
  position: relative;
  text-align: center;
  width: 80%;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.relative {
  position: relative !important;
}

.resource-section-title {
  position: relative;
  text-align: center;
  width: 100%;
}




.resource-section-title a{
  color: #000;
}

.resource-section-icon, 
.resource-section-title {
    margin-left: auto;
    margin-right: auto;
}

.col-lg-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333333%;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}


@media (min-width: 992px) {
  .col-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333333%;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
  }
}

@media (min-width: 1440px) {
  .col-xxl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
  }
}

@media (min-width: 1600px) {
  .col-xxxl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66666667%;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
  }
}

@media only screen and (max-width: 768px){
	.resource-section.col-lg-4 {
	    flex: 0 0 50%;
	    max-width: 50%;
	}
 
	.resource-section-title h4 {
	    font-size: 20px;
	    line-height: 1.1;
	}
 
	.global_section_subtitle p {
	    padding: 0px 5%;
	}
 
}